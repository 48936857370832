import React, { useEffect, useState } from 'react';
import { Switch, Tooltip } from '@mui/material'

import withResourceStateChange from '@components/withResourceStateChange';

const ToggleRequiredState = ({ initialRequiredState, onChange }) => {
  const [required, setRequired] = useState(initialRequiredState !== undefined ? initialRequiredState : true);

  useEffect(() => {
    setRequired(initialRequiredState)
  }, [initialRequiredState])

  const onHandleRequiredChange = (event) => {
    const required = event.target.checked;
    setRequired(required);
    onChange('required', required)
  };

  return (
    <Tooltip title="Layers required disables rarity and will have a 100% occurrence.">
      <Switch
        checked={required}
        onChange={onHandleRequiredChange}
        onClick={(event) => event.stopPropagation()} // Prevents clicks (ex, accordion toggles)
        onMouseDown={(event) => event.stopPropagation()} // Prevents effects (ex, parent ripples)
      />
    </Tooltip>
  )
}

export default ToggleRequiredState;
export const PersistToggleRequiredState = withResourceStateChange(ToggleRequiredState);
