// This flattens to tree and adds a depth and parent key.
// TODO: This might need refactor because we moved the depth and parentId key to the state
//  Previously, it was managed here locally. Circle back to this if we ever have nested Layers. 
//  If not then refactor to remove it.
function flattenTree(items, depth = 0, parentId = null) {
    return items.flatMap((item, index) => {
        const newItem = {
            ...item,
            state: {
                ...item.state,
                depth,
                parentId,
            },
        };
        if (item.children.length > 0) {
            return [newItem, ...flattenTree(item.children, depth + 1, newItem.id)];
        }
        return newItem;
    }, []);
}

// Builds tree from flattened tree
function buildTree(items, parentId = null) {
    return items
        .filter((item) => item.state.parentId === parentId)
        .map((item) => ({ ...item, children: buildTree(items, item.id) }));
}

// For flattened trees
function getDescendentsForFlatTrees(items, parentId) {
    if (items.length === 0) {
        return [];
    }
    const children = items.filter((item) => {
        return item.state.parentId === parentId;
    });
    const childrenIds = children.map((item) => item.id);
    return [
        ...children,
        ...items
            .filter((item) => childrenIds.indexOf(item.state.parentId) > -1)
            .map((item) =>
                getDescendentsForFlatTrees(items, item.state.parentId)
            ),
    ];
}

function getDescendentsCount(items, id) {
    return getDescendentsForFlatTrees(items, id).length + 1;
}

export {
    flattenTree,
    buildTree,
    getDescendentsForFlatTrees,
    getDescendentsCount
}