import {
  Alert,
  Backdrop,
  Box,
  Button,
  Fade,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import React, { useState } from 'react';

import ConfirmDeleteModal from '@components/ConfirmDeleteModal';
import { INVITE_COLLABORATOR } from '@mutations/invitations';
import PropTypes from 'prop-types';
import { REVOKE_COLLABORATOR_ACCESS } from '@mutations/collaborator';
import { Tooltip } from '@mui/material';
import { buildAppendFields } from '@schema/helpers';
import { ellipseAddress } from '@lib/helper'
import { useMutation } from '@apollo/client';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 0,
  height: 500
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Invitations = ({ invitations }) =>
  <List dense={true} sx={{ mb: 2, p: 0 }}>
    {invitations.map(invitation =>
      <ListItem sx={{ pl: 0, pr: 0 }}>
        <ListItemText
          primary={invitation.invitee.walletAddress}
          secondary={
            <Box sx={{ textTransform: 'capitalize' }}>
              {invitation.role} Role - {invitation.invitationStatus}
            </Box>
          } />
      </ListItem>
    )}
  </List>


const ShareModal = ({ collection }) => {
  const [inviteCollaborator, { loading }] = useMutation(INVITE_COLLABORATOR);
  const [revokeCollaboratorAccess] = useMutation(REVOKE_COLLABORATOR_ACCESS);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    resetForm();
    clearMessages();
    setOpen(false);
  };

  const [walletAddress, setWalletAddress] = useState('');
  const handleWalletAddressChange = (event) => {
    setWalletAddress(event.target.value)
  }

  const [role, setRole] = useState('');
  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const clearMessages = () => {
    setInviteSuccess(false)
    setInviteError(undefined)
  }

  const resetForm = () => {
    setRole(0);
    setValue(0);
    setWalletAddress('');
  }

  const [inviteSuccess, setInviteSuccess] = useState(false);
  const [inviteError, setInviteError] = useState(undefined);
  const handleInvite = () => {
    inviteCollaborator({
      variables: {
        walletAddress,
        provider: 'ethereum',
        role,
        collectionId: collection.id
      },
      update: (cache, { data: { inviteCollaborator } }) => {
        cache.modify({
          id: cache.identify({
            id: collection.id,
            __typename: 'Collection',
          }),
          fields: buildAppendFields('invitations', `Invitation:${inviteCollaborator.id}`),
        });
      },
      onError: (error) => {
        setInviteError(error)
      },
      onCompleted: () => {
        setInviteSuccess(true)
        resetForm();
      }
    })
  }

  const [confirmOpen, setConfirmOpen] = useState(false);

  const onHandleClick = (event) => {
    setConfirmOpen(true);
    event.stopPropagation();
  };

  const onHandleDelete = (event, collaborator) => {
    revokeCollaboratorAccess({
      variables: {
        collectionId: collection.id,
        collaboratorId: collaborator.id
      },
      update: (cache, { data: { revokeCollaboratorAccess } }) => {
        cache.evict({ id: cache.identify({ id: collaborator.id, __typename: 'Collaborator' }) });
        cache.gc();
      },
      optimisticResponse: {
        revokeCollaboratorAccess: {
          __typename: "Collaborator",
          id: collaborator.id
        }
      }
    })
    setConfirmOpen(false);
    event.stopPropagation();
    event.preventDefault();
  }

  const onHandleCancel = (event) => {
    setConfirmOpen(false);
    event.stopPropagation();
  };

  return (
    <React.Fragment>
      <Button variant="outlined" size="small" onClick={handleOpen}>Share</Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleTabChange}>
                <Tab label="Invite" {...a11yProps(0)} />
                <Tab label="Invitations" {...a11yProps(1)} />
                <Tab label="Collaborators" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {inviteError && <Alert severity="error">
                  {inviteError.message}
                </Alert>}
                {inviteSuccess && <Alert severity="success">
                  Invite successfully sent! The invitee will see an invitation in the app after connecting their wallet.
                </Alert>}
                <Alert severity="warning" sx={{ mb: 1 }}>
                  Collaborators have full access to your collection and will be able to make changes to your collection.
                </Alert>
                <TextField
                  required
                  disabled={loading}
                  value={walletAddress}
                  onChange={handleWalletAddressChange}
                  label="Wallet Address"
                  size="small"
                  sx={{
                    mb: 1
                  }}
                />
                <FormControl fullWidth size="small" required sx={{ mb: 1 }} disabled={loading}>
                  <InputLabel id="role">Role</InputLabel>
                  <Select
                    required
                    labelId="role"
                    value={role}
                    label="Role"
                    onChange={handleChange}
                  >
                    <MenuItem value={"viewer"}>Viewer - This person can view, but cannot make changes </MenuItem>
                  </Select>
                </FormControl>
                <Button
                  disabled={loading}
                  variant="outlined"
                  size="small"
                  onClick={handleInvite}>
                  Add Collaborator
                </Button>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              {collection.invitations.length > 0
                ? <Invitations invitations={collection.invitations} />
                :
                <Box>There are no invites awaiting action.</Box>
              }
            </TabPanel>
            <TabPanel value={value} index={2}>
              {collection.collaborators.length > 0 ?
                <List dense={true} sx={{ mb: 2, p: 0 }}>
                  <ListItem sx={{ pl: 0, pr: 0, mb: 1.5 }}>
                    <ListItemText
                      primary={
                        <Tooltip title={collection.owner.walletAddress}>
                          <Typography component="span">
                            {ellipseAddress(collection.owner.walletAddress)}
                          </Typography>
                        </Tooltip>
                      } />
                    <ListItemSecondaryAction sx={{ right: 1 }}>
                      <FormControl size="small" sx={{ mr: 1 }} disabled>
                        <InputLabel>Role</InputLabel>
                        <Select
                          value={"owner"}
                          label="Role"
                          onChange={handleChange}
                          sx={{
                            '& .MuiSelect-select': {
                              pt: 0.9,
                              pb: 0.9
                            }
                          }}
                        >
                          <MenuItem value={"owner"}>Owner</MenuItem>
                        </Select>
                      </FormControl>
                      <Button
                        disabled
                        variant="outlined"
                        color="warning">
                        Revoke
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                  {collection.collaborators.map(collaborator =>
                    <ListItem sx={{ pl: 0, pr: 0, mb: 1.5 }} key={collaborator.id}>
                      <ListItemText
                        primary={
                          <Tooltip title={collaborator.user.walletAddress}>
                            <Typography component="span">
                              {ellipseAddress(collaborator.user.walletAddress)}
                            </Typography>
                          </Tooltip>
                        } />
                      <ListItemSecondaryAction sx={{ right: 1 }}>
                        <FormControl size="small" sx={{ mr: 1 }}>
                          <InputLabel>Role</InputLabel>
                          <Select
                            value={collaborator.role}
                            label="Role"
                            onChange={handleChange}
                            sx={{
                              '& .MuiSelect-select': {
                                pt: 0.9,
                                pb: 0.9
                              }
                            }}
                          >
                            <MenuItem value={"viewer"}>Viewer</MenuItem>
                          </Select>
                        </FormControl>
                        <Button
                          variant="outlined"
                          color="warning"
                          onClick={onHandleClick}>
                          Revoke
                        </Button>
                        <ConfirmDeleteModal
                          open={confirmOpen}
                          name={collaborator.user.walletAddress}
                          onDelete={(event) => onHandleDelete(event, collaborator)}
                          onClose={onHandleCancel}
                          onCancel={onHandleCancel}
                          actionName="revoke" />
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                </List>
                : <Box>There are no collaborators for this collection.</Box>}
            </TabPanel>
          </Box>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}

export default ShareModal;