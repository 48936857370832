import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material'
import React, { useEffect, useState } from 'react';
import { WEIGHT_NORMAL, rarityByWeight } from '@lib/helper'

import Rarity from '@components/Rarity';
import withResourceStateChange from '@components/withResourceStateChange';

const SelectWeightState = ({ initialWeightState, onChange, disabled }) => {
  const [weight, setWeight] = useState(initialWeightState || WEIGHT_NORMAL);

  useEffect(() => {
    setWeight(initialWeightState)
  }, [initialWeightState])

  const handleWeightChange = (event) => {
    const weight = event.target.value;
    setWeight(weight);
    onChange('weight', weight)
  };

  const select = <Select
    value={weight}
    label="Rarity"
    onChange={handleWeightChange}
    onClick={(event) => event.stopPropagation()} // Prevents clicks (ex, accordion toggles)
    onMouseDown={(event) => event.stopPropagation()} // Prevents effects (ex, parent ripples)
    disabled={disabled}
  >
    {Object.entries(rarityByWeight).map(([weight, rarity]) =>
      <MenuItem key={weight} value={parseInt(weight)}>
        <Rarity weight={weight} />
      </MenuItem>)}
  </Select>

  return <FormControl size="small">
    <InputLabel>Rarity</InputLabel>
    {disabled
      ?
      <Tooltip title="Rarity is inapplicable when a layer is required because it will always occur.">
        {select}
      </Tooltip>
      :
      select
    }

  </FormControl>
}

export default SelectWeightState;
export const PersistSelectWeightState = withResourceStateChange(SelectWeightState);
