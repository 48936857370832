import 'react-dropzone-uploader/dist/styles.css';

import { Box, Button, Link, Typography } from '@mui/material';
import { CREATE_VARIATION, UPDATE_VARIATION_FILE } from '@mutations/variations';
import React, { useContext } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';

import CreativeSpaceContext from '@contexts/CreativeSpaceContext';
import Dropzone from 'react-dropzone-uploader';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Input from './Input';
import { PRESIGN_URL } from '@queries/uploads';
import { ScrollableBox } from '@components/Layout';
import SubmitButton from './SubmitButton';
import { ToolbarConsumer } from '@contexts/ToolbarContext';
import UploadPreview from './UploadPreview';
import { WEIGHT_NORMAL } from '@lib/helper'
import { buildAppendFields } from '@schema/helpers';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';

const Layout = (props) => {
  const {
    input,
    previews,
    submitButton,
    dropzoneProps,
    files,
    extra: { maxFiles },
  } = props;

  return (
    files.length > 0 ?
      <ScrollableBox>
        <Box {...dropzoneProps} sx={{ height: '100%', overflow: 'hidden' }}>
          <Box sx={{ minWidth: 500, maxWidth: 650 }}>
            {previews}
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                pl: 1,
                pr: 1,
              }}
            >
              {input}
              {files.length > 0 && submitButton}
            </Box>
          </Box>

        </Box>
      </ScrollableBox>
      :
      <Box {...dropzoneProps} sx={{ height: '100%', overflow: 'hidden' }}>
        {input}
      </Box>
  );
};

const VariationsBaseUploader = (props) => {
  const { collectionId, layerId, variations, onFileUploaded } = props;

  // Get Presigned URL
  const client = useApolloClient();
  const getPresignedUrl = async (file) =>
    client
      .query({
        query: PRESIGN_URL,
        variables: {
          collectionId,
          layerId,
          fileName: file.name,
        },
      })
      .then(({ data }) => data.uploadUrl.url);

  const getUploadParams = async ({ file, meta: { name } }) => {
    const uploadUrl = await getPresignedUrl(file);
    return { method: 'put', body: file, url: uploadUrl, meta: { layerId } };
  };

  const handleChangeStatus = (fileWithMeta, status) => {
    switch (status) {
      case 'headers_received':
        onFileUploaded(fileWithMeta, status)
        break;
      default:
        break;
    }
  };

  // Dismiss the upload progress
  const handleSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
  };

  const getFilesFromEvent = e => {
    return new Promise(resolve => {
      getDroppedOrSelectedFiles(e).then(chosenFiles => {
        resolve(chosenFiles.map(f => f.fileObject))
      })
    })
  }

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      onSubmit={handleSubmit}
      addClassNames={{ dropzone: props.className }}
      accept="image/jpeg,image/png,image/gif"
      inputWithFilesContent={'Upload More'}
      InputComponent={Input}
      PreviewComponent={UploadPreview}
      LayoutComponent={Layout}
      SubmitButtonComponent={SubmitButton}
      getFilesFromEvent={getFilesFromEvent}
      maxSizeBytes={1024 * 1024 * 5} // 5mb
      {...props}
    />
  );
};

VariationsBaseUploader.defaultProps = {
  collectionId: undefined,
  layerId: undefined,
  variations: [],
};

const SytledVariationsBaseUploader = styled((props, otherProps) => (
  <VariationsBaseUploader {...props} />
))(({ theme }) => ({
  // Master Styles: https://github.com/fortana-co/react-dropzone-uploader/blob/master/src/styles.css
  '&&': {
    color: theme.palette.text.primary,
    border: 'none',
    '& .dzu-inputLabel': {
      color: theme.palette.text.primary,
    },
    '&.dzu-dropzoneActive': {
      backgroundColor: theme.palette.grey[800],
    },
  },
}));;

const variationFileParams = (file, fileUrl) => ({
  fileName: file.name,
  fileSize: file.size,
  fileType: file.type,
  fileLastModified: file.lastModifiedDate.toISOString(),
});

const VariationsUploader = ({ variations, ...props }) => {
  const { setSelectedVariation } = useContext(CreativeSpaceContext);

  // Create Variation
  const [createVariation] = useMutation(CREATE_VARIATION);

  const createVariationParams = (layerId, file, fileUrl) => ({
    variables: {
      layerId,
      ...variationFileParams(file)
    },
    optimisticResponse: {
      createVariation: {
        id: uuidv4(),
        __typename: 'Variation',
        name: file.name.split('.')[0],
        file: {
          ...file,
          mimeType: file.type,
          url: fileUrl,
          name: file.name,
          lastModified: file.lastModifiedDate.toISOString(),
          size: file.size,
        },
        state: {
          weight: WEIGHT_NORMAL
        }
      },
    },
    update: (cache, { data: { createVariation } }) => {
      cache.modify({
        id: cache.identify({ id: layerId, __typename: 'Layer' }),
        fields: buildAppendFields(
          'variations',
          `Variation:${createVariation.id}`
        ),
      });
      if (variations.length === 0) {
        setSelectedVariation(createVariation)
      }
    },
    onCompleted: ({ createVariation }) => {
      if (variations.length === 0) {
        setSelectedVariation(createVariation)
      }
    },
  });

  // Update Variation File
  const [updateVariationFile] = useMutation(UPDATE_VARIATION_FILE);

  const onFileUploaded = (fileWithMeta, status) => {
    const { file, xhr, meta } = fileWithMeta;
    const { layerId } = meta;
    const variation = variations.find(variation => variation.file.name === file.name)
    if (variation) {
      updateVariationFile({
        variables: {
          variationId: variation.id,
          ...variationFileParams(file, xhr.responseURL)
        }
      })
    } else {
      createVariation(createVariationParams(layerId, file, xhr.responseURL));
    }
  }

  const inputContent = <Box sx={{ textAlign: 'center' }}>
    <FileUploadIcon sx={{ fontSize: 72 }} /> <br />
    <Typography component="div" sx={{ fontSize: 32, p: 1, m: 2, mt: 1 }}>
      Drop Images Here
      <Typography component="p" variant="body1" sx={{ p: 1, m: 2, mt: 1 }}>
        Drag and drop folders or files into this area
      </Typography>
    </Typography>
    {variations.length > 0 && <ToolbarConsumer>
      {({ toggleShowUploadPanel }) =>
        <Button
          variant="contained"
          size="small"
          onClick={toggleShowUploadPanel}
        >
          Show Variations
        </Button>
      }
    </ToolbarConsumer>}
  </Box>

  return (
    <SytledVariationsBaseUploader
      inputContent={(files, extra) => inputContent}
      onFileUploaded={onFileUploaded}
      {...props} />
  )
}

export default VariationsUploader;

export const VariationComponentUploader = ({ variations, ...props }) => {
  const variationComponent = variations[0];
  // Update Variation File
  const [updateVariationFile] = useMutation(UPDATE_VARIATION_FILE);

  const onFileUploaded = (fileWithMeta, status) => {
    const { file, xhr } = fileWithMeta;
    updateVariationFile({
      variables: {
        variationId: variationComponent.id,
        ...variationFileParams(file, xhr.responseURL)
      }
    })
  }

  const inputContent = <Box sx={{ textAlign: 'center' }}>
    <FileUploadIcon sx={{ fontSize: 72 }} /> <br />
    <Typography component="div" sx={{ fontSize: 32, p: 1, m: 2, mt: 1 }}>
      Upload your Variation Component
      <Typography component="p" variant="body1" sx={{ p: 1, m: 2, mt: 1 }}>
        Drag or drop a file into this area. Variation Components only allow 1 file.
      </Typography>
      <Typography component="p" variant="h6" sx={{ p: 1, m: 1, mt: 2, mb: 0.5, }}>
        What are Variation Components?
      </Typography>
      <Typography component="p" variant="body1" sx={{ p: 0, maxWidth: 600, textAlign: 'justify' }}>
        Variation Components (or Components for short) are complex variations with multiple layers that have different layer ordering. They allow you to have more control over how your variations render in the layer stack.{' '}
        <Link href="https://gold-error-e2d.notion.site/Variation-Components-Draft-c4c8c39c06bb43bb91d2451d2de3e5a3" target="_blank">Learn More</Link>
      </Typography>
    </Typography>
    {variationComponent?.file && <ToolbarConsumer>
      {({ toggleShowUploadPanel }) =>
        <Button
          variant="contained"
          size="small"
          onClick={toggleShowUploadPanel}
        >
          Show Variation Component
        </Button>
      }
    </ToolbarConsumer>}
  </Box>;

  return (
    <SytledVariationsBaseUploader
      inputContent={(files, extra) => inputContent}
      onFileUploaded={onFileUploaded}
      maxFiles={1}
      {...props} />
  )
}