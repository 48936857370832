import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { getCombinationDna, getFileWithCacheBreak, getVariationsWithComponents } from '@lib/helper';
import { mergeCombination, mergeGifCombination } from '@lib/merge-images';

import CreativeSpaceContext from '@contexts/CreativeSpaceContext';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import SelectableMedia from '@components/SelectableMedia';
import ToolbarContext from '@contexts/ToolbarContext';
import { keyframes } from '@mui/system';
import { saveAs } from 'file-saver';
import { styled } from '@mui/material/styles';

const rainbow = keyframes`
  0% { fill: #b202bd; }
  15% { fill: #2800ff; }
  30% { fill: #007ef5; }
  45% { fill: #37ba00; }
  60% { fill: #fad000; }
  75% { fill: #f07900; }
  90% { fill: #e60000; }
  100% { fill: #b202bd; }
`;

const GifPlayButton = styled(PlayCircleOutlineIcon)({
  fontSize: 72,
  opacity: 0.8,
  transitionTimingFunction: 'ease-in',
  transition: '0.5s',
  '&:hover': {
    opacity: 1,
    animationPlayState: "paused",
  },
  cursor: 'pointer',
  textFillColor: "transparent",
  animation: `${rainbow} 15s infinite ease`
});

const getFiles = (combination) => getVariationsWithComponents(combination).map(variation => getFileWithCacheBreak(variation)).filter(v => v).reverse();

const NftCombination = ({ gifPreviewControl, selected, combination, onSelect, ...props }) => {
  const LOADING_IMAGE_STATE = 'loading_image';
  const LOADING_IMAGE_COMPLETE_STATE = 'loading_complete';
  const LOADING_GIF_READY_STATE = 'ready';
  const LOADING_GIF_STATE = 'loading_gif';
  const LOADING_GIF_COMPLETE_STATE = 'loading_gif_complete';
  const {
    MIN_IMAGE_WIDTH,
    imageWidth,
    isTransparentBackground
  } = useContext(ToolbarContext);
  const { collection } = useContext(CreativeSpaceContext);

  const [previewImageSrc, setPreviewImageSrc] = useState(undefined);
  const [previewImageLoadingState, setPreviewImageLoadingState] = useState(undefined);
  const [loadingGifState, setLoadingGifState] = useState(LOADING_GIF_READY_STATE);
  const files = getFiles(combination);
  const combinationHasGif = files.find(file => file.mimeType === 'image/gif');

  useEffect(() => {
    setLoadingGifState(LOADING_GIF_READY_STATE);
    const width = collection.state.imageWidth;
    const height = collection.state.imageHeight;

    // Only show loading on first load
    if (!previewImageSrc) {
      setPreviewImageLoadingState(LOADING_IMAGE_STATE);
    }

    mergeCombination(files, width, height)
      .then((b64) => b64 === 'data:,' ? setPreviewImageSrc(undefined) : setPreviewImageSrc(b64))
      .then(() => setPreviewImageLoadingState(LOADING_IMAGE_COMPLETE_STATE))
      .catch(error => console.error(error))
    return () => {
      setPreviewImageSrc(undefined)
      setLoadingGifState(LOADING_GIF_READY_STATE)
    }
  }, [combination]);

  const generateGif = () => {
    const width = collection.state.imageWidth;
    const height = collection.state.imageHeight;

    if (gifPreviewControl) {
      setLoadingGifState(LOADING_GIF_STATE)

      mergeGifCombination(files, width, height)
        .then(url => setPreviewImageSrc(url))
        .then(() => setLoadingGifState(LOADING_GIF_COMPLETE_STATE))
        .catch(error => console.error(error))
    }
  }

  const onSelectPropOptional = onSelect ? { onSelect: () => onSelect(combination) } : {}

  const onDownloadImage = () => {
    saveAs(previewImageSrc, `${getCombinationDna(combination)}.png`);
  }

  const onDownloadMetadata = () => {
    const metadata = combination.reduce((accum, variation) => ({
      ...accum,
      attributes: [
        ...accum.attributes,
        {
          trait_name: variation.layer.name,
          trait_value: variation.name
        }
      ]
    }), {
      dna: getCombinationDna(combination),
      attributes: []
    })

    const blob = new Blob([JSON.stringify(metadata)], { type: "application/json" });
    saveAs(blob, `${getCombinationDna(combination)}.json`);
  }

  return <Box sx={{ position: 'relative' }}>
    {combinationHasGif && gifPreviewControl &&
      <Box>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          {loadingGifState === LOADING_GIF_STATE && <CircularProgress />}
          {loadingGifState === LOADING_GIF_READY_STATE &&
            previewImageLoadingState === LOADING_IMAGE_COMPLETE_STATE &&
            <GifPlayButton onClick={generateGif} />
          }
        </Box>
        <Alert severity="info" sx={{ display: 'flex' }}>
          Multiple gif layers will animate sequentially until we improve this feature. You can help us shape our roadmap by sharing your feedback with us on Discord.
        </Alert>
      </Box>}
    {combinationHasGif && !gifPreviewControl &&
      <Box sx={{
        position: 'absolute',
        top: 10,
        right: 20,
        textTransform: 'uppercase',
        background: '#336af4',
        borderRadius: 1,
        pr: 0.5,
        pl: 0.5,
        fontSize: 10,
        fontWeight: 600,
        zIndex: 999,
        fontFamily: 'arial',
      }}>
        gif
      </Box>
    }
    <SelectableMedia
      loading={previewImageLoadingState !== LOADING_IMAGE_COMPLETE_STATE}
      minWidth={MIN_IMAGE_WIDTH}
      width={imageWidth}
      maxWidth={imageWidth}
      selected={selected}
      imageUrl={previewImageSrc}
      imageAlt={getCombinationDna(combination)}
      isTransparentBackground={isTransparentBackground}
      {...onSelectPropOptional}
      {...props}
    />
    <Box sx={{ textAlign: 'right', mr: 1, mb: 1 }}>
      <Button variant="outlined" size="small" onClick={onDownloadImage} sx={{ mr: 1 }}>
        <Typography sx={{ fontSize: '0.8rem', textTransform: 'uppercase' }}>Image</Typography>
      </Button >
      <Button variant="outlined" size="small" onClick={onDownloadMetadata}>
        <Typography sx={{ fontSize: '0.8rem', textTransform: 'uppercase' }}>Metadata</Typography>
      </Button >
    </Box>
  </Box>
}

NftCombination.defaultProps = {
  gifPreviewControl: false,
  selected: false,
  combination: [],
  onSelect: undefined,
}

export default NftCombination;
