import { Box } from '@mui/material';
import React from 'react';
import Variation from '@components/Variation';

const Variations = ({ variations, selectedVariationId, onSelect }) =>
  <Box sx={{
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    p: 1,
  }}>
    {variations.map((variation) => (
      <Variation
        key={variation.id}
        variation={variation}
        selected={selectedVariationId === variation.id}
        onSelect={onSelect}
      />
    ))}
  </Box>

Variations.defaultProps = {
  variations: [],
  selectedVariationId: undefined,
  onSelect: () => { },
};

export default Variations;
