import { Box, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import React, { useContext } from 'react';

import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { CreativeSpaceConsumer } from '@contexts/CreativeSpaceContext';
import FileUploadIcon from '@mui/icons-material/FileUpload';
// Icons
import SquareIconButton from '@components/SquareIconButton';
import Toolbar from '@components/Toolbar';
import ToolbarContext from '@contexts/ToolbarContext';
import TuneIcon from '@mui/icons-material/Tune';
import { UPDATE_LAYER_STATE } from '@mutations/layers';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useMutation } from '@apollo/client';

export const ToggleLayerVisibilityTool = ({ layer, ...rest }) => {
  const [updateLayerState] = useMutation(UPDATE_LAYER_STATE);
  const visibility = layer?.state.visibility;
  const onClick = (event) => {
    const { __typename, ...rest } = layer.state
    updateLayerState({
      variables: {
        layerId: layer.id,
        state: {
          ...rest,
          visibility: !visibility
        }
      },
      update: (cache, { data: { updateLayer } }) => {
        cache.modify({
          id: cache.identify({ id: layer.id, __typename: 'Layer' }),
          fields: {
            state: (state) => ({ ...state, visibility: updateLayer.state.visibility })
          }
        });
      },
      optimisticResponse: {
        updateLayer: {
          ...layer,
          state: {
            ...layer.state,
            visibility: !visibility
          }
        }
      }
    })
    event.stopPropagation();
  }

  return (
    <SquareIconButton
      sx={Toolbar.Styles.TabControlIconStyles}
      selected={visibility}
      onClick={onClick}
      tooltipTitle="This will show/hide the layer and include/exclude it from the generation"
      {...rest}
    >
      {visibility ? (
        <VisibilityIcon fontSize="small" />
      ) : (
        <VisibilityOffIcon fontSize="small" />
      )}
    </SquareIconButton>

  );
};

const ToggleRightSidePanel = () => {
  const { rightSidePanel, setRightSidePanel } = useContext(ToolbarContext);

  const onChange = (event, panel) => {
    setRightSidePanel(panel)
  };

  const buttonStyles = {
    m: 0,
    pt: 0.33,
    pb: 0.33,
    pl: 0.7,
    pr: 0.7
  }

  return (
    <ToggleButtonGroup
      value={rightSidePanel}
      exclusive
      onChange={onChange}
      sx={{ m: 0, p: 0 }}
      size="small"
    >
      <ToggleButton value={1} sx={buttonStyles}>
        <Tooltip
          title="Open Variation Component preview. Variation Components are complex variations with multiple layers that have different layer ordering."
          enterDelay={500}
          leaveDelay={200}>
          <AutoAwesomeMotionIcon fontSize="small" />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value={0} sx={buttonStyles}>
        <Tooltip
          title="Open the NFT preview and various settings"
          enterDelay={500}
          leaveDelay={200}>
          <TuneIcon fontSize="small" />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup >
  );
};

const ToggleUploadPanel = () => {
  const { showUploadPanel, toggleShowUploadPanel } = useContext(ToolbarContext);
  return (
    <SquareIconButton
      sx={{
        ...Toolbar.Styles.TabControlIconStyles,
        '&:last-child': {
          mr: 0
        }
      }}
      selected={showUploadPanel}
      onClick={toggleShowUploadPanel}
      tooltipTitle="Opens the upload panel to upload variations"
    >
      <FileUploadIcon fontSize="small" />
    </SquareIconButton>
  );
};

const VariationsEditorToolbar = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Toolbar sx={{ mr: 1 }}>
        <Toolbar.ControlsLeft noDivider={true}>
          <ToggleUploadPanel />
        </Toolbar.ControlsLeft>
      </Toolbar>
      <Toolbar sx={{ flexGrow: 1 }}>
        <Toolbar.ControlsLeft>
          {/* Local context state */}
          <Toolbar.Tools.ZoomInTool />
          <Toolbar.Tools.ZoomOutTool />
          <Toolbar.Tools.ResetZoomTool />
          <Toolbar.Tools.ShowTransparencyTool />
        </Toolbar.ControlsLeft>
        <Toolbar.ControlsLeft>
          {/* Server state mutations */}
          <CreativeSpaceConsumer>
            {({ getSelectedLayer }) => {
              const layer = getSelectedLayer();
              return (
                <ToggleLayerVisibilityTool layer={layer} />
              )
            }}
          </CreativeSpaceConsumer>
        </Toolbar.ControlsLeft>
        <Toolbar.ControlsRight noDivider={true}>
          <ToggleRightSidePanel />
        </Toolbar.ControlsRight>
      </Toolbar>
    </Box>
  );
};

export default VariationsEditorToolbar;
