import { List, ListItemButton, ListItemText } from '@mui/material';
import React, { useState } from 'react';

import { ContextMenu } from '@components/ContextMenus';
import EditableResourceName from '@components/EditableResourceName';
import PropTypes from 'prop-types';
import TextWithCount from '@components/TextWithCount';

const Subcollection = ({ selected, subcollection, onClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <ListItemButton
        sx={{ pl: 1, pr: 1, borderRadius: 1 }}
        selected={selected}
        disableGutters={true}
        onClick={onClick}
        onContextMenu={handleClick}
      >
        <ListItemText
          primary={
            <TextWithCount
              text={<EditableResourceName resource={subcollection} sx={{ pl: 1, mr: 2 }} />}
              count={subcollection.layers.length}
              sx={{ display: 'flex', justifyContent: 'space-between' }} />
          }
        />
      </ListItemButton>
      <ContextMenu
        resource={subcollection}
        anchorEl={anchorEl}
        onClose={handleClose} />
    </React.Fragment>
  )
}

const Subcollections = ({
  subcollections,
  selectedSubcollectionId,
  onSelect,
}) => {
  return (
    <List
      sx={{ width: '100%' }}
      component="nav"
      dense={true}
    >
      {subcollections.map((subcollection) =>
        <Subcollection
          key={subcollection.id}
          selected={selectedSubcollectionId === subcollection.id}
          subcollection={subcollection}
          onClick={() => onSelect(subcollection)} />)}
    </List>
  );
};

export default Subcollections;

Subcollections.propTypes = {
  subcollections: PropTypes.array.isRequired,
  selectedSubcollectionId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onSelect: PropTypes.func,
};
