import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { ToolbarConsumer } from '@contexts/ToolbarContext';

// Original from library here:
// https://github.com/fortana-co/react-dropzone-uploader/blob/master/src/SubmitButton.tsx
const SubmitButton = (props) => {
  const { className, style } = props;
  return (
    <div className={className} style={style}>
      <ToolbarConsumer>
        {({ toggleShowUploadPanel }) =>
          <Button
            variant="contained"
            size="small"
            onClick={toggleShowUploadPanel}
          >
            View Variations
          </Button>
        }
      </ToolbarConsumer>
    </div>
  );
};

SubmitButton.propTypes = {
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  style: PropTypes.object,
  buttonStyle: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  content: PropTypes.node,
  onSubmit: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  extra: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    reject: PropTypes.bool.isRequired,
    dragged: PropTypes.arrayOf(PropTypes.any).isRequired,
    accept: PropTypes.string.isRequired,
    multiple: PropTypes.bool.isRequired,
    minSizeBytes: PropTypes.number.isRequired,
    maxSizeBytes: PropTypes.number.isRequired,
    maxFiles: PropTypes.number.isRequired,
  }).isRequired,
};

export default SubmitButton;
