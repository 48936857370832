import React, { useEffect, useState } from 'react';

import { isResourceVariationComponent } from '@lib/helper';

const CreativeSpaceContext = React.createContext({});

export default CreativeSpaceContext;

export const CreativeSpaceProvider = (props) => {
  // TODO: Split context up to have 1 for each editor

  //// Variations Editor
  // Collection
  const { collection, children } = props;
  const { subcollections } = collection;
  let creativeSpaceContext = {
    collection,
  };

  // Subcollection States
  const firstSubcollection = subcollections[0];

  const [selectedSubcollectionId, setSelectedSubcollectionId] = useState(
    firstSubcollection?.id
  );

  const getSelectedSubcollection = () =>
    collection.subcollections.find(
      (subcollection) => subcollection.id === selectedSubcollectionId
    );

  const setSelectedSubcollection = (subcollection) =>
    setSelectedSubcollectionId(subcollection.id);

  creativeSpaceContext = {
    ...creativeSpaceContext,
    getSelectedSubcollection,
    setSelectedSubcollection,
  };

  // Layer States
  const firstLayer = firstSubcollection?.layers[0];

  const [
    selectedLayerIdBySubcollectionId,
    setSelectedLayerIdBySubcollectionId,
  ] = useState(firstLayer ? { [selectedSubcollectionId]: firstLayer.id } : {});

  const getSelectedLayerId = () =>
    selectedLayerIdBySubcollectionId[selectedSubcollectionId];

  const getSelectedLayer = () =>
    getSelectedSubcollection()?.layers.find(
      (layer) => layer.id === getSelectedLayerId()
    );

  const setSelectedLayer = (layer) => {
    setSelectedLayerIdBySubcollectionId({
      ...selectedLayerIdBySubcollectionId,
      [selectedSubcollectionId]: layer.id,
    });
  };

  creativeSpaceContext = {
    ...creativeSpaceContext,
    getSelectedLayer,
    setSelectedLayer,
  };

  // Variation States
  const [selectedVariationsByLayerId, setSelectedVariationsByLayerId] =
    useState({});

  const getSelectedVariationId = (layerId = getSelectedLayerId()) =>
    selectedVariationsByLayerId[layerId];

  const getSelectedVariation = (
    layer = getSelectedLayer(),
    variationId = getSelectedVariationId()
  ) => layer?.variations.find(
    (variation) => variation.id === variationId
  );

  const setSelectedVariation = (variation, layerId = getSelectedLayerId()) => {
    setSelectedVariationsByLayerId({
      ...selectedVariationsByLayerId,
      [layerId]: variation.id,
    });
  };

  creativeSpaceContext = {
    ...creativeSpaceContext,
    getSelectedVariation,
    setSelectedVariation,
  };

  // NFT Preview
  const getNftPreviewImageSources = () => {
    const subcollection = getSelectedSubcollection();
    if (!subcollection) {
      return [];
    }
    return subcollection.layers
      .filter(layer => layer.state.visibility)
      .filter(layer => !isResourceVariationComponent(layer))
      .map(layer => getSelectedVariation(layer, getSelectedVariationId(layer.id)))
      .filter(variation => variation)
  }

  creativeSpaceContext = {
    ...creativeSpaceContext,
    getNftPreviewImageSources
  };

  // Effects
  // Selected the first variation on Subcollection change
  useEffect(() => {
    const selected = {}
    getSelectedSubcollection()?.layers.forEach((layer) => {
      if (layer.variations.length) {
        selected[layer.id] = layer.variations[0].id
      } else {
        selected[layer.id] = undefined
      }
    })
    setSelectedVariationsByLayerId({
      ...selectedVariationsByLayerId,
      ...selected
    })
  }, [selectedSubcollectionId])

  //// Collection Editor
  const [selectedCombination, setSelectedCombination] = useState(undefined);

  creativeSpaceContext = {
    ...creativeSpaceContext,
    selectedCombination,
    setSelectedCombination,
  };

  return (
    <CreativeSpaceContext.Provider value={creativeSpaceContext}>
      {children}
    </CreativeSpaceContext.Provider>
  );
};

export const { Consumer: CreativeSpaceConsumer } = CreativeSpaceContext;
