import { Box } from '@mui/material';
import { COLLECTION_DETAILS } from '@queries/collections';
import Collections from '@components/Collections';
import CreativeSpace from '@components/CreativeSpace';
import { CreativeSpaceProvider } from '@contexts/CreativeSpaceContext';
import LobbyLayout from '@components/LobbyLayout';
import React from 'react';
import StyledRouter from '@components/StyledRouter';
import { UserConsumer } from '@contexts/UserContext';
import gengenLoading from '@images/loading.gif';
import { useQuery } from '@apollo/client';

const withCollectionData = (Component) => (props) => {
  const { loading, error, data } = useQuery(COLLECTION_DETAILS, {
    variables: { id: props.id },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  if (loading) {
    return <Box sx={{
      textAlign: 'center',
      position: "fixed",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <img src={gengenLoading} width={200} />
    </Box>
  };
  if (error) return <p>Error :(</p>;

  return (
    <CreativeSpaceProvider collection={data.collection}>
      <Component {...props} />
    </CreativeSpaceProvider>
  );
};

const CollectionDetails = withCollectionData(CreativeSpace);

const withLobbyLayout = (Component) => (props) => {
  return (
    <LobbyLayout>
      <React.Fragment>
        <UserConsumer>
          {({ currentUser }) => <Component {...props} currentUser={currentUser} />}
        </UserConsumer>
      </React.Fragment>
    </LobbyLayout>
  );
};
const CollectionsLayout = withLobbyLayout(Collections);

const CollectionClientOnlyPages = () => {
  return (
    <StyledRouter basepath="/collections">
      <CollectionDetails path="/:id/*" />
      <CollectionsLayout path="/" />
    </StyledRouter>
  );
};

export default CollectionClientOnlyPages;
