import React, { useContext, useState } from 'react';
import { getFileWithCacheBreak, getResourceColor, isResourceVariationComponent } from '@lib/helper';

import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { ContextMenu } from '@components/ContextMenus';
import EditableResourceName from '@components/EditableResourceName';
import SelectableMedia from '@components/SelectableMedia';
import ToolbarContext from '@contexts/ToolbarContext';
import { Typography } from '@mui/material';

const Variation = (props) => {
  const {
    MIN_IMAGE_WIDTH,
    imageWidth,
    isTransparentBackground
  } = useContext(ToolbarContext);

  const { variation, selected, onSelect } = props;
  const isVariationComponent = isResourceVariationComponent(variation);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const variationComponentStyles = {
    ...(isVariationComponent ? {
      ...(selected ? {
        borderColor: getResourceColor(variation),
      } : {
        borderBottomWidth: 2,
        borderBottomStyle: 'solid',
        borderBottomColor: getResourceColor(variation),
      })

    } : {})
  }

  return (
    <React.Fragment>
      <SelectableMedia
        sx={{
          ...(isVariationComponent ? variationComponentStyles : {}),
        }}
        minWidth={MIN_IMAGE_WIDTH}
        width={imageWidth}
        maxWidth={imageWidth}
        key={variation.id}
        selected={selected}
        onContextMenu={handleClick}
        title={
          <React.Fragment>
            <Typography variant="div" color="text.secondary">
              <EditableResourceName resource={variation} />
            </Typography>

            {isVariationComponent && <AutoAwesomeMotionIcon fontSize="small" sx={{ mr: 0.5, color: getResourceColor(variation) }} />}
          </React.Fragment>
        }
        // This allows us to break the cache when there a is a new variation with the same file name
        imageUrl={getFileWithCacheBreak(variation)?.url}
        imageAlt={variation.file?.name}
        onSelect={() => onSelect(variation)}
        isTransparentBackground={isTransparentBackground}
      />
      <ContextMenu
        resource={variation}
        anchorEl={anchorEl}
        onClose={handleClose} />
    </React.Fragment>
  );
};

Variation.defaultProps = {
  variation: {},
  selected: false,
  onSelect: () => { },
  onDelete: () => { }
};

export default Variation;
