import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListSubheader,
  Paper,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { validate as uuidValidate, v4 as uuidv4 } from 'uuid';

import CollectionGallery from '@components/CollectionGallery';
import CreativeSpaceContext from '@contexts/CreativeSpaceContext';
import DownloadIcon from '@mui/icons-material/Download';
import EditableResourceName from '@components/EditableResourceName';
import EditableResourceState from '@components/EditableResourceState';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { GENERATED_COLLECTION_DETAILS } from '@queries/generated-collections';
import { GENERATE_COLLECTION } from '@mutations/collections';
import LabelStack from '@components/LabelStack';
import { ScrollableBox } from '@components/Layout';
import { totalCombinationCount } from '@lib/merge-images'
import { uniqueItems } from '@lib/helper'

const GeneratedCollection = ({ generatedCollection, onShowGalleryClick }) => {
  const [metadata, setMetadata] = useState({})
  const hasPersisted = !uuidValidate(generatedCollection.id);
  const { startPolling, stopPolling } = useQuery(GENERATED_COLLECTION_DETAILS, {
    // We never want to fetch onload especially when there optimistic update has a UUID in generatedCollction.id
    skip: generatedCollection.generationStatus === 'success' || generatedCollection.generationStatus === 'failed' || !hasPersisted,
    variables: {
      generatedCollectionId: generatedCollection.id
    },
    update: (cache, { data: { generatedCollection } }) => {
      cache.modify({
        id: cache.identify({
          id: generatedCollection.id,
          __typename: 'GeneratedCollection',
        }),
        fields: {
          generationStatus: () => generatedCollection.generationStatus,
          jobSucceededAt: () => generatedCollection.jobSucceededAt
        }
      });
    },
  });

  if (hasPersisted) {
    switch (generatedCollection.generationStatus) {
      case 'failed':
      case 'success':
        stopPolling();
        break;
      default:
        startPolling(5000);
        break;
    }
  }

  useEffect(() => {
    if (generatedCollection?.collectionMetadataUrl) {
      fetch(generatedCollection.collectionMetadataUrl)
        .then(response => response.json())
        .then(metadata => setMetadata(metadata))
        .catch(error => console.error("Couldn't fetch metadata", generatedCollection.externalId))
    }
  }, [generatedCollection])


  return <Box
    sx={{
      border: '1px solid #3d3d3d',
      borderRadius: 2,
      p: 3,
      mt: 2,
    }}>
    <Box>
      <Box sx={{
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        {(() => {
          switch (generatedCollection.generationStatus) {
            case 'ready':
              return <React.Fragment>
                <Typography variant="h6">Preparing your data before joining the queue...</Typography>
                <Box>
                  <CircularProgress disableShrink size={20} sx={{ m: 1 }} />
                </Box>
              </React.Fragment>
            case 'queued':
              return <React.Fragment>
                <Typography variant="h6">Waiting for the next robot to generate your collection...</Typography>
                <Box>
                  <CircularProgress disableShrink size={20} sx={{ m: 1 }} />
                </Box>
              </React.Fragment>
            case 'generating':
              return <React.Fragment>
                <Typography variant="body">We are generating your collection now. This may take a while depending on your collection's assets and size.</Typography>
                <Box>
                  <CircularProgress disableShrink size={20} sx={{ m: 1 }} />
                </Box>
              </React.Fragment>
            case 'failed':
              return <Typography variant="body" sx={{ textAlign: 'center' }}>
                This is embarrassing. Seems like the job failed!
                Please try generating again. If the problem continues, contact us on Discord!
              </Typography>
            case 'success':
              return <Box>
                <LabelStack sx={{ mb: 1 }} label="Generated Collection ID" content={generatedCollection.externalId} />
                <LabelStack sx={{ mb: 1 }} label="Title" content={metadata.title} />
                <LabelStack sx={{ mb: 1 }} label="Description" content={metadata.description} />
                <LabelStack sx={{ mb: 1 }} label="Collection Size" content={metadata.combinationsCount === metadata.size ?
                  metadata.size
                  :
                  <React.Fragment>
                    {(metadata.combinationsCount || 0).toLocaleString()}/{(metadata.size || 0).toLocaleString()}
                    <Tooltip title="If you are getting less than your requested size, then this might mean you do not have enough traits.">
                      <ErrorOutlineIcon />
                    </Tooltip>
                  </React.Fragment>
                } />
                <LabelStack sx={{ mb: 1 }} label="Image Width (px)" content={metadata.imageHeight} />
                <LabelStack sx={{ mb: 1 }} label="Image Height (px)" content={metadata.imageHeight} />
                {generatedCollection.generationStatus &&
                  <LabelStack
                    label="Generated Date"
                    content={generatedCollection.jobSucceededAt.split('T')[0]} />}
                <Box sx={{ mt: 2 }}>
                  <Button variant="outlined" onClick={onShowGalleryClick} sx={{ mr: 1 }}>
                    Collection Gallery
                  </Button>
                  {/* <Button variant="outlined" onClick={() => { }}>
                    <DownloadIcon /> Download
                  </Button> */}
                </Box>
              </Box>
            default:
              break;
          }
        })()}
      </Box>
    </Box>
  </Box>
}

const ProjectMetaData = ({ collection }) => {
  const [generateCollection] = useMutation(GENERATE_COLLECTION);
  const runningJobs = collection.generatedCollections
    .filter(({ generationStatus }) => generationStatus !== 'success')
    .filter(({ generationStatus }) => generationStatus !== 'failed')
  const hasRunningJobs = runningJobs.length > 0;

  const { title, description, size } = collection.state;

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Box sx={{
        pt: 2,
        pb: 2,
        mt: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography variant="h5">
          Project
        </Typography>
        <Button
          variant="contained"
          disabled={hasRunningJobs || !title || !description || !size}
          onClick={() => {
            generateCollection({
              variables: {
                collectionId: collection.id
              },
              optimisticResponse: {
                generateCollection: {
                  __typename: 'GeneratedCollection',
                  id: uuidv4(),
                  collectionId: collection.id,
                  generationStatus: 'queued',
                  tokensMetadataUrl: null,
                  collectionMetadataUrl: null,
                  jobSucceededAt: null,
                  externalId: null,
                  galleryBaseUrl: null
                },
              },
              update: (cache, { data: { generateCollection } }) => {
                cache.modify({
                  id: cache.identify({ id: generateCollection.collectionId, __typename: 'Collection' }),
                  fields: {
                    generatedCollections: (generatedCollections) => {
                      const newResources = [
                        { __ref: `GeneratedCollection:${generateCollection.id}` },
                        ...generatedCollections
                      ]
                      return uniqueItems(newResources, '__ref')
                    }
                  }
                });
              },
            })
          }}>
          {hasRunningJobs && 'Generating...'}
          {!hasRunningJobs && "Generate"}
        </Button>
      </Box>

      <List sx={{ width: '100%', m: 0 }}>
        <ListItem sx={{ pl: 0, pr: 0 }}>
          <EditableResourceState
            label="Title"
            inputRef={undefined}
            resource={collection}
            sx={{
            }}
            noFocusBorder={false}
            disabled={false}
            initialText={collection.state.title}
            onNextState={(resource, newValue) => ({ ...resource.state, title: newValue })}
            helperText="ex, GENGEN Creator Pass Collection"
          />
        </ListItem>
        <ListItem sx={{ pl: 0, pr: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <EditableResourceState
            label="Size"
            inputRef={undefined}
            resource={collection}
            sx={{
              mr: 1,
              width: 'auto',
              '& input': {
              }
            }}
            noFocusBorder={false}
            disabled={false}
            initialText={collection.state.size}
            onNextState={(resource, newValue) => ({ ...resource.state, size: parseInt(newValue) })}
            numbersOnly={true}
            numberIsAllowed={({ value }) => {
              const size = parseInt(value) || 0;
              return 0 <= size && size <= totalCombinationCount(collection)
            }}
          />
          <LabelStack label="Max Collection Size" content={totalCombinationCount(collection).toLocaleString()} sx={{ textAlign: 'right' }} />
        </ListItem>
        <ListItem sx={{ pl: 0, pr: 0 }}>
          <EditableResourceState
            label="Description"
            inputRef={undefined}
            resource={collection}
            noFocusBorder={false}
            disabled={false}
            initialText={collection.state.description || ""}
            onNextState={(resource, newValue) => ({ ...resource.state, description: newValue })}
            multiline
            rows={5}
          />
        </ListItem>
      </List>
      <List sx={{ width: '100%' }}>
        <ListItem sx={{ pl: 0, pr: 0 }}>
          <EditableResourceState
            label="Image Width (px)"
            inputRef={undefined}
            resource={collection}
            sx={{ width: 'auto', mr: 1 }}
            noFocusBorder={false}
            disabled={false}
            initialText={collection.state.imageWidth}
            onNextState={(resource, newValue) => ({ ...resource.state, imageWidth: parseInt(newValue) })}
            numbersOnly={true}
            numberIsAllowed={({ value }) => {
              const size = parseInt(value) || 0;
              return 0 <= size
            }}
          />
          <EditableResourceState
            label="Image Height (px)"
            inputRef={undefined}
            resource={collection}
            sx={{ width: 'auto' }}
            noFocusBorder={false}
            disabled={false}
            initialText={collection.state.imageHeight}
            onNextState={(resource, newHeight) => ({ ...resource.state, imageHeight: parseInt(newHeight) })}
            numbersOnly={true}
            numberIsAllowed={({ value }) => {
              const size = parseInt(value) || 0;
              return 0 <= size
            }}
          />
        </ListItem>
      </List>
    </Box >
  )
}

const GeneratedCollections = () => {
  const { collection } = useContext(CreativeSpaceContext);
  const [showGallery, setShowGallery] = useState(false)
  const [galleryGeneratedCollection, setSalleryGeneratedCollection] = useState(undefined)

  return (
    <Paper elevation={4} sx={{ height: '100%', width: '100%' }}>
      {showGallery && <CollectionGallery generatedCollection={galleryGeneratedCollection} onClose={() => setShowGallery(false)} />}
      <Box sx={{ width: 960, margin: '0 auto', display: 'flex', height: '100%' }}>
        <Box sx={{ flexGrow: 1, flexBasis: 0 }}>
          <ProjectMetaData collection={collection} />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <ScrollableBox>
            <Box sx={{ pl: 1, pr: 2, pt: 4, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h5">
                Generated Collections
              </Typography>
              <Box sx={{ pb: 10 }}>
                {collection.generatedCollections.length > 0 ?
                  collection.generatedCollections.map((generatedCollection) =>
                    <GeneratedCollection
                      key={generatedCollection.id}
                      generatedCollection={generatedCollection}
                      onShowGalleryClick={() => {
                        setSalleryGeneratedCollection(generatedCollection)
                        setShowGallery(true)
                      }} />
                  )
                  :
                  <Box
                    sx={{
                      border: '1px solid #3d3d3d',
                      borderRadius: 2,
                      p: 3,
                      mt: 2,
                    }}>
                    <Box sx={{
                      display: 'flex',
                      flexGrow: 1,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <Typography variant="body" sx={{ textAlign: 'center' }}>
                        Set your project details and generate your collection!
                      </Typography>
                    </Box>
                  </Box>
                }
              </Box>
            </Box>
          </ScrollableBox>
        </Box>
      </Box>
    </Paper>
  )
}

export default GeneratedCollections;