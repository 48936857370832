import { Box, Divider, Paper } from '@mui/material';
import React, { useContext } from 'react';

import FindReplaceIcon from '@mui/icons-material/FindReplace';
import SquareIconButton from '@components/SquareIconButton';
import ToolbarContext from '@contexts/ToolbarContext';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

const ControlsLeft = (props) => {
  const { children, sx, noDivider = false, ...other } = props;

  return (
    <div {...other}>
      <Box sx={{ display: 'flex', alignItems: 'center', p: 0, ...sx }}>
        {children}
        {!noDivider && <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ alignSelf: 'stretch', height: 'auto', m: 0.25, mr: 1 }}
        />}
      </Box>
    </div>
  );
};

const ControlsRight = (props) => {
  return (
    <ControlsLeft
      {...props}
      style={{ width: '100%' }}
      sx={{
        flexGrow: 1,
        flexDirection: 'row-reverse',
        justifyContent: 'flex-start',
      }}
    >
      {props.children}
    </ControlsLeft>
  );
};

const Toolbar = ({ children, sx }) => {
  return (
    <Paper elevation={15} sx={{ mb: 1, p: 0.8, maxHeight: 40, ...sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, p: 0 }}>
        {children}
      </Box>
    </Paper>
  );
};

const tabControlIconStyles = {
  p: 0.5,
  mr: 0.5,
  '&:last-child': {
    mr: 1,
  },
};

const ZoomInTool = () => {
  const { zoomIn } = useContext(ToolbarContext);

  return (
    <SquareIconButton
      sx={tabControlIconStyles}
      onClick={zoomIn}
      tooltipTitle="Increases the size of the gallery"
    >
      <ZoomInIcon fontSize="small" />
    </SquareIconButton>
  );
};

const ZoomOutTool = () => {
  const { zoomOut } = useContext(ToolbarContext);

  return (
    <SquareIconButton
      sx={tabControlIconStyles}
      onClick={zoomOut}
      tooltipTitle="Decreases the size of the gallery"
    >
      <ZoomOutIcon fontSize="small" />
    </SquareIconButton>
  );
};

const ResetZoomTool = () => {
  const { zoomReset } = useContext(ToolbarContext);

  return (
    <SquareIconButton
      sx={tabControlIconStyles}
      onClick={zoomReset}
      tooltipTitle="Resets size of the gallery to normal"
    >
      <FindReplaceIcon fontSize="small" />
    </SquareIconButton>
  );
};

const ShowTransparencyTool = () => {
  const {
    isTransparentBackground,
    toggleTransparentBackground
  } = useContext(ToolbarContext);

  return (
    <SquareIconButton
      sx={tabControlIconStyles}
      selected={isTransparentBackground}
      onClick={toggleTransparentBackground}
      tooltipTitle="Changes the background to checkers to better see transparency"
    >
      <WallpaperIcon fontSize="small" />
    </SquareIconButton>
  );
};

Toolbar.ControlsLeft = ControlsLeft;
Toolbar.ControlsRight = ControlsRight;
Toolbar.Tools = {
  ZoomInTool,
  ZoomOutTool,
  ResetZoomTool,
  ShowTransparencyTool,
}
Toolbar.Styles = {
  TabControlIconStyles: tabControlIconStyles
}

export default Toolbar;
