import React, { useState } from 'react';

const ToolbarContext = React.createContext({});

export default ToolbarContext;

export const ToolbarProvider = ({ children }) => {
  const NORMAL_IMAGE_WIDTH = 210;
  const MIN_IMAGE_WIDTH = 150;
  const ZOOM_PERCENTAGE_CHANGE = 0.125;
  let context = {
    NORMAL_IMAGE_WIDTH,
    MIN_IMAGE_WIDTH,
    ZOOM_PERCENTAGE_CHANGE,
  };

  // Zoom Tools
  const [imageWidth, setImageWidth] = useState(NORMAL_IMAGE_WIDTH);
  const zoomIn = () => {
    setImageWidth(imageWidth * (1 + ZOOM_PERCENTAGE_CHANGE));
  };
  const zoomOut = () =>
    setImageWidth(
      imageWidth < MIN_IMAGE_WIDTH
        ? imageWidth
        : imageWidth * (1 - ZOOM_PERCENTAGE_CHANGE)
    );
  const zoomReset = () => setImageWidth(NORMAL_IMAGE_WIDTH);

  context = {
    ...context,
    imageWidth,
    zoomIn,
    zoomOut,
    zoomReset,
  };

  // Show Transparent Background Tool
  const [isTransparentBackground, setTransparentBackground] = useState(false);
  const toggleTransparentBackground = () =>
    setTransparentBackground(!isTransparentBackground);

  context = {
    ...context,
    isTransparentBackground,
    toggleTransparentBackground,
  };

  // Toggle NFT Preview
  const [showNftPreview, setShowNftPreview] = useState(false);
  const toggleNftPreview = () => setShowNftPreview(!showNftPreview);

  context = {
    ...context,
    showNftPreview,
    toggleNftPreview,
  };

  // Display Side Panel
  const [rightSidePanel, setRightSidePanel] = useState(0);

  context = {
    ...context,
    rightSidePanel,
    setRightSidePanel,
  };

  // Toggle Upload Panel
  const [showUploadPanel, setShowUploadPanel] = useState(false);
  const toggleShowUploadPanel = () => setShowUploadPanel(!showUploadPanel);

  context = {
    ...context,
    showUploadPanel,
    setShowUploadPanel,
    toggleShowUploadPanel,
  };

  return (
    <ToolbarContext.Provider value={context}>
      {children}
    </ToolbarContext.Provider>
  );
};

export const { Consumer: ToolbarConsumer } =
  ToolbarContext;
