import {
  AppBar,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Toolbar,
} from '@mui/material';
import { LeftColumn, RightColumn, ScrollableBox } from '@components/Layout';
import React, { useEffect, useState } from 'react';
import { TraitFilterContextConsumer, TraitFilterContextProvider } from '@contexts/TraitFilterContext';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Content } from '@components/Layout';
import CurrentFilters from '@components/CurrentFilters';
import GalleryModal from './GalleryModal';
import SelectableMedia from '@components/SelectableMedia';
import SquareIconButton from '@components/SquareIconButton';
import TraitFilters from '@components/TraitFilters';

const CollectionGallery = ({ generatedCollection, onClose }) => {
  const [tokens, setTokens] = useState([])

  useEffect(() => {
    if (generatedCollection?.tokensMetadataUrl) {
      fetch(generatedCollection.tokensMetadataUrl)
        .then(response => response.json())
        .then(setTokens)
        .catch(error => console.error("Couldn't fetch tokens data", generatedCollection.externalId))
    }
  }, [generatedCollection])


  const [perPage, setPerPage] = useState(15);
  const [page, setPage] = useState(1);
  const onPageChange = (event, newPage) => setPage(newPage)

  const currentTokens = (filteredTokens) => {
    const startIndex = (page - 1) * perPage;
    const endINdex = (page * perPage);
    return filteredTokens.slice(startIndex, endINdex)
  }

  const updatePerPage = (event) => {
    setPerPage(event.target.value);
    setPage(1)
  };

  return (
    <Paper
      elevation={3}
      sx={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 999, p: 1 }}
    >
      <TraitFilterContextProvider tokens={tokens} onFilterChange={() => setPage(1)}>
        <Box sx={{ mb: 2 }}>
          <SquareIconButton onClick={onClose}>
            <ArrowBackIosNewIcon sx={{ fontSize: '1.2rem', p: 0 }} />
          </SquareIconButton>
        </Box>
        <Content sx={{ flexDirection: 'row' }}>
          <LeftColumn>
            <LeftColumn.ColumnBox sx={{ flexDirection: 'column' }}>
              <TraitFilterContextConsumer>
                {({ variationNamesByTraitName, tokensByVariationName, selectedFilters, setSelectedFilters }) =>
                  <TraitFilters
                    variationNamesByTraitName={variationNamesByTraitName}
                    getCount={(key) => tokensByVariationName[key].length}
                    onChange={({ target: { name, checked } }) => {
                      const newState = {
                        ...selectedFilters,
                        [name]: checked
                      };
                      setSelectedFilters(newState)
                    }}
                    isChecked={(key) => selectedFilters[key]} />
                }
              </TraitFilterContextConsumer>
            </LeftColumn.ColumnBox>
          </LeftColumn>
          <RightColumn>
            <ScrollableBox sx={{ display: 'flex', flexDirection: 'column', mb: 5 }}>
              <TraitFilterContextConsumer>
                {({ selectedFilters, removeFilter }) =>
                  <CurrentFilters filters={selectedFilters} onDelete={removeFilter} />
                }
              </TraitFilterContextConsumer>
              <TraitFilterContextConsumer>
                {({ filteredTokens }) =>
                  <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {currentTokens(filteredTokens).map((token) => {
                      return <GalleryModal
                        generatedCollection={generatedCollection}
                        token={token}
                        onClickContent={(openModal, propsOverride) =>
                          <SelectableMedia
                            key={token.name}
                            title={token.name}
                            selected={false}
                            imageUrl={`${generatedCollection.galleryBaseUrl}/${token.imageFileName}`}
                            imageAlt={token.name}
                            isTransparentBackground={false}
                            onSelect={openModal}
                            {...propsOverride}
                          />
                        }
                      />
                    }
                    )}
                  </Box>
                }
              </TraitFilterContextConsumer>
            </ScrollableBox>
            <AppBar position='sticky' sx={{ bottom: 0, height: 72 }}>
              <TraitFilterContextConsumer>
                {({ filteredTokens }) =>
                  <Toolbar>
                    <Pagination
                      sx={{ flexGrow: 1 }}
                      count={Math.ceil(filteredTokens.length / perPage)}
                      page={page}
                      onChange={onPageChange}
                      siblingCount={5}
                      boundaryCount={5}
                      shape="rounded" />
                    <FormControl sx={{ m: 1, minWidth: 80 }}>
                      <InputLabel id="demo-simple-select-autowidth-label">Per Page</InputLabel>
                      <Select
                        value={perPage}
                        onChange={updatePerPage}
                        autoWidth
                        label="Per Page"
                      >
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </Toolbar>
                }
              </TraitFilterContextConsumer>
            </AppBar>
          </RightColumn>
        </Content>
      </TraitFilterContextProvider>
    </Paper >
  )
}

export default CollectionGallery;