import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Tooltip,
  Typography
} from '@mui/material';
import { LeftColumn, RightColumn, ScrollableBox } from '@components/Layout';
import NftCombinations, { NftCombination } from '@components/NftCombinations';
import { PreviewFilterContextConsumer, PreviewFilterContextProvider } from '@contexts/PreviewFilterContext';
import React, { useContext } from 'react';

import CollectionEditorToolbar from '@components/CollectionEditorToolbar';
import CollectionsIcon from '@mui/icons-material/Collections';
import { CreativeSpaceConsumer } from '@contexts/CreativeSpaceContext';
import CreativeSpaceContext from '@contexts/CreativeSpaceContext';
import CurrentFilters from '@components/CurrentFilters';
import LayersIcon from '@mui/icons-material/Layers';
import Rarity from '@components/Rarity';
import TextWithCount from '@components/TextWithCount';
import { ToolbarConsumer } from '@contexts/ToolbarContext';
import TraitFilters from '@components/TraitFilters';
import { ellipseAddress } from '@lib/helper';
import { getCombinationDna } from '@lib/helper';

const SubcollectionFilter = ({ subcollections, getCount, onSubcollectionToggle, isChecked }) => {
  return (
    <LeftColumn.ColumnAccordion
      title={
        <TextWithCount
          text={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CollectionsIcon sx={{ ml: -1, mr: 1 }} fontSize="small" />Subcollections
            </Box>
          }
          count={subcollections.length}
          sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }} />
      }
    >
      <FormGroup>
        {subcollections.map((subcollection) =>
          <FormControlLabel
            key={subcollection.id}
            onChange={(event) => onSubcollectionToggle(event, subcollection)}
            disableTypography={true}
            label={
              <TextWithCount
                text={subcollection.name}
                count={getCount(subcollection)}
                sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }} />
            }
            sx={{ mr: 0 }}
            control={
              <Checkbox id={subcollection.id} checked={isChecked(subcollection)} size="small" />
            }
          />
        )}
      </FormGroup>
    </LeftColumn.ColumnAccordion>
  )
}

const KeyValueText = ({ keyName, keyValue }) => {
  return (
    <Typography component="span" sx={{ display: 'flex', justifyContent: 'space-between' }}>
      {keyName}
      <Typography component="span">
        {keyValue.length > 20
          ?
          <Tooltip title={keyValue}><Typography component="span">{ellipseAddress(keyValue, 5)}</Typography></Tooltip>
          :
          keyValue
        }
      </Typography>
    </Typography>
  )
}

const CombinationDetails = ({ selectedCombination, subcollection }) => {
  return <Box>
    {!selectedCombination && <Box sx={{ p: 1 }}>Please select a combination</Box>}
    {selectedCombination &&
      <Box>
        <NftCombination
          key={getCombinationDna(selectedCombination)}
          gifPreviewControl={true}
          minWidth={'100%'}
          width={'100%'}
          maxWidth={'100%'}
          combination={selectedCombination} />
        <List
          dense={true}
          sx={{ width: '100%' }}
          subheader={<ListSubheader>Details</ListSubheader>}>
          <ListItem>
            <ListItemText
              primary={
                <KeyValueText keyName="DNA" keyValue={btoa(getCombinationDna(selectedCombination))} />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <KeyValueText keyName="Subcollection" keyValue={subcollection.name} />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <KeyValueText keyName="Subcollection Rarity" keyValue={<Rarity weight={subcollection.state?.weight} />} />
              }
            />
          </ListItem>
        </List>
        <List
          dense={true}
          sx={{ width: '100%' }}
          subheader={
            <ListSubheader component="span" sx={{ display: 'flex', justifyContent: 'space-between' }}>
              Traits <span>Rarity</span>
            </ListSubheader>
          }>
          {selectedCombination?.map(variation =>
            <ListItem key={variation.id}>
              <ListItemText
                primary={
                  <KeyValueText keyName={variation.layer.name} keyValue={<Rarity weight={variation.layer.state.weight} />} />
                }
                secondary={
                  <KeyValueText keyName={variation.name} keyValue={<Rarity weight={variation.state.weight} />} />
                }
              />
            </ListItem>
          )}
        </List>

      </Box>
    }
  </Box>
}

const CollectionPreview = ({ collection }) => {
  const { setSelectedCombination } = useContext(CreativeSpaceContext);
  const { subcollections } = collection;

  // This is a hacky way to rebuild the necessary datastructure to retrieve the details
  const getResource = (resourceName, keyName) => {
    if (!keyName) {
      return "";
    }
    const split = keyName.split(':');
    return {
      __typename: resourceName,
      layer: {
        name: split[0]
      },
      name: split[1]
    }
  }
  return (
    <PreviewFilterContextProvider
      subcollections={subcollections}
      onCombinationReady={(combinations) => setSelectedCombination(combinations[0])}>
      <LeftColumn>
        <Box sx={{ p: 1 }}>
          <PreviewFilterContextConsumer>
            {({ shuffleCombinations }) =>
              <Tooltip
                title="Generate different combinations for preview. This will generate 500 combinations or the max possible with the current variations."
                enterDelay={500}
                leaveDelay={200}>
                <Button variant="outlined" fullWidth onClick={shuffleCombinations}>Shuffle</Button>
              </Tooltip>
            }
          </PreviewFilterContextConsumer>
        </Box>
        <LeftColumn.ColumnBox sx={{ flexDirection: 'column', }}>
          <PreviewFilterContextConsumer>
            {({ filteredSubcollections, getCombinationsByResource, setFilterByResource, getFilterByResource }) =>
              <SubcollectionFilter
                subcollections={filteredSubcollections}
                getCount={(resource) => getCombinationsByResource(resource).length}
                onSubcollectionToggle={(event, resource) => setFilterByResource(resource, event.target.checked)}
                isChecked={getFilterByResource}
              />
            }
          </PreviewFilterContextConsumer>
          <LeftColumn.ColumnBoxHeader>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
              <LayersIcon sx={{ ml: -1, mr: 1 }} fontSize="small" /> Traits
            </Box>
          </LeftColumn.ColumnBoxHeader>
          <PreviewFilterContextConsumer>
            {({ variationNamesByTraitName, getCombinationsByResource, getFilterByResource, setFilterByResource }) =>
              <TraitFilters
                variationNamesByTraitName={variationNamesByTraitName}
                getCount={(keyName) => getCombinationsByResource(getResource('Variation', keyName))?.length}
                isChecked={(keyName) => getFilterByResource(getResource('Variation', keyName))}
                onChange={({ target: { name: keyName, checked } }) => setFilterByResource(getResource('Variation', keyName), checked)}
              />
            }
          </PreviewFilterContextConsumer>
        </LeftColumn.ColumnBox>
      </LeftColumn>
      <RightColumn>
        <Box sx={{ pt: 1 }}>
          <CollectionEditorToolbar />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
          <Paper elevation={4} sx={{ flexGrow: 1 }}>
            <ScrollableBox>
              <PreviewFilterContextConsumer>
                {({ selectedFilters, removeFilter }) =>
                  <CurrentFilters
                    filters={selectedFilters}
                    getName={(name) => {
                      const split = name.split(':')
                      switch (split[0]) {
                        case 'Subcollection':
                          return split[2];
                        case 'Variation':
                          return split[split.length - 1];
                        default:
                          return name;
                      }
                    }}
                    onDelete={removeFilter}
                    getIcon={(name) => {
                      if (name.includes('Subcollection')) {
                        return <CollectionsIcon fontSize="small" />;
                      }
                      return <LayersIcon fontSize="small" />;
                    }}
                  />
                }
              </PreviewFilterContextConsumer>
              <Box>
                <PreviewFilterContextConsumer>
                  {({ filteredCombinations }) =>
                    <CreativeSpaceConsumer>
                      {({ selectedCombination, setSelectedCombination }) =>
                        <NftCombinations
                          selectedCombination={selectedCombination}
                          combinations={filteredCombinations}
                          onSelect={setSelectedCombination}
                        />
                      }
                    </CreativeSpaceConsumer>
                  }
                </PreviewFilterContextConsumer>
              </Box>
            </ScrollableBox>
          </Paper>
          <ToolbarConsumer>
            {({ rightSidePanel }) =>
              rightSidePanel >= 0 && <RightColumn.SidePanel>
                {((panel) => {
                  switch (panel) {
                    case 0:
                      return <CreativeSpaceConsumer>
                        {({ selectedCombination }) => {
                          const layerId = selectedCombination?.[0]?.layer.id;
                          const subcollection = subcollections.find(subcollection => subcollection.layers.find(layer => layer.id === layerId))
                          return <CombinationDetails
                            selectedCombination={selectedCombination}
                            subcollection={subcollection}
                          />
                        }}
                      </CreativeSpaceConsumer>
                    default:
                      break;
                  }
                })(rightSidePanel)}
              </RightColumn.SidePanel>
            }
          </ToolbarConsumer>
        </Box>
      </RightColumn>
    </PreviewFilterContextProvider>
  );
}

export default CollectionPreview;