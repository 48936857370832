import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress
} from '@mui/material';

import React from 'react';

const SelectableMedia = (props) => {
  const {
    loading,
    title,
    minWidth,
    width,
    maxWidth,
    imageUrl,
    imageAlt,
    selected,
    isTransparentBackground,
    onSelect,
    onContextMenu,
    sx
  } = props;

  const cardStyles = {
    minWidth,
    width,
    maxWidth,
    mr: 1,
    mb: 1,
    ...(selected
      ? {
        border: 3,
        borderColor: 'primary.main',
      }
      : {}),
    ...sx
  };

  const imageStyles = {
    ...(isTransparentBackground
      ? {
        background:
          'repeating-conic-gradient(#ffffff 0% 25%, #c2c2c2 0% 50%) 50% / 20px 20px',
      }
      : {}),
  };

  const cardContent = <React.Fragment>
    {title && <CardContent sx={{ p: 1, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
      {title}
    </CardContent>}
    {loading && <CardContent sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
      <CircularProgress />
    </CardContent>}
    {!loading &&
      <CardMedia
        component="img"
        height="auto"
        image={imageUrl}
        crossOrigin="anonymous"
        alt={imageAlt}
        sx={{
          imageRendering: "pixelated",
          ...imageStyles
        }}
      />
    }
  </React.Fragment>

  return (
    <Card
      sx={cardStyles}
      elevation={6}
      {...(onContextMenu ? { onContextMenu } : {})}
    >
      {typeof onSelect === 'function'
        ?
        // Setting a component to prevent button descendent errors
        <CardActionArea onClick={onSelect} component="a">
          {cardContent}
        </CardActionArea>
        :
        cardContent
      }
    </Card>
  );
};

SelectableMedia.defaultProps = {
  loading: false,
  title: undefined,
  minWidth: 250,
  maxWidth: 250,
  variation: {},
  imageUrl: undefined,
  imageAlt: undefined,
  selected: false,
  onSelect: undefined,
};

export default SelectableMedia;
