import { formatBytes, formatDuration } from 'react-dropzone-uploader';

import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PropTypes from 'prop-types';
import React from 'react';
import SquareIconButton from '@components/SquareIconButton';
import Typography from '@mui/material/Typography';

const LinearProgressWithLabel = ({ value }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, mr: 1 }}>
      <Box sx={{ width: '80%', ml: 1, mr: 1 }}>
        <LinearProgress variant="determinate" value={value} sx={{ width: 150 }} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

// This component is an derivative of the library's original:
// https://github.com/fortana-co/react-dropzone-uploader/blob/master/src/Preview.tsx
const UploadPreview = (props) => {
  const {
    className,
    style,
    imageClassName,
    imageStyle,
    fileWithMeta: { cancel, remove, restart },
    meta: {
      name = '',
      size = 0,
      percent,
      previewUrl,
      status,
      validationError,
      duration,
    },
    isUpload,
    canCancel,
    canRemove,
    canRestart,
    extra: { minSizeBytes },
  } = props;

  let title = `${name || '?'}, ${formatBytes(size)}`;
  if (duration) title = `${title}, ${formatDuration(duration)}`

  if (status === 'error_file_size' || status === 'error_validation') {
    return (
      <div className={className} style={style}>
        <span className="dzu-previewFileNameError">{title}</span>
        {status === 'error_file_size' && (
          <span>{size < minSizeBytes ? 'File too small' : 'File too big'}</span>
        )}
        {status === 'error_validation' && (
          <span>{String(validationError)}</span>
        )}
        {canRemove && (
          <SquareIconButton onClick={remove}>
            <CloseIcon />
          </SquareIconButton>
        )}
      </div>
    );
  }

  if (
    status === 'error_upload_params' ||
    status === 'exception_upload' ||
    status === 'error_upload'
  ) {
    title = `${title} (upload failed)`;
  }
  if (status === 'aborted') title = `${title} (cancelled)`;

  return (
    <div className={className} style={{ ...style, borderBottom: 'none', borderRadius: 0 }}>
      {
        previewUrl && (
          <img
            className={imageClassName}
            style={{
              ...imageStyle,
              border: '1px solid #3d3d3d',
              borderRadius: 2,
              boxSizing: 'content-box',
              maxHeight: 60,
              imageRendering: "-webkit-optimize-contrast",
            }}
            src={previewUrl}
            alt={title}
            title={title}
          />
        )
      }
      {!previewUrl && <span className="dzu-previewFileName">{title}</span>}

      <div className="dzu-previewStatusContainer">
        {isUpload && (
          <LinearProgressWithLabel value={Math.round(percent)} />
        )}

        <UploadAction
          status={status}
          canCancel={canCancel}
          onCancel={cancel}
          canRemove={canRemove}
          onRemove={remove}
          canRestart={canRestart}
          onRestart={restart} />
      </div>
    </div>
  );
};

const UploadAction = ({ status, canCancel, onCancel, canRemove, onRemove, canRestart, onRestart }) => {
  return (
    <React.Fragment>
      {status === 'uploading' && canCancel && (
        <SquareIconButton onClick={onCancel}>
          <PauseIcon />
        </SquareIconButton>
      )}
      {
        status !== 'preparing' &&
        status !== 'getting_upload_params' &&
        status !== 'uploading' &&
        canRemove && (
          <SquareIconButton onClick={onRemove}>
            <CloseIcon />
          </SquareIconButton>
        )
      }
      {
        [
          'error_upload_params',
          'exception_upload',
          'error_upload',
          'aborted',
          'ready',
        ].includes(status) &&
        canRestart && (
          <SquareIconButton onClick={onRestart}>
            <PlayArrowIcon />
          </SquareIconButton>
        )
      }
    </React.Fragment>
  )
}

UploadPreview.propTypes = {
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  style: PropTypes.object,
  imageStyle: PropTypes.object,
  fileWithMeta: PropTypes.shape({
    file: PropTypes.any.isRequired,
    meta: PropTypes.object.isRequired,
    cancel: PropTypes.func.isRequired,
    restart: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    xhr: PropTypes.any,
  }).isRequired,
  // copy of fileWithMeta.meta, won't be mutated
  meta: PropTypes.shape({
    status: PropTypes.oneOf([
      'preparing',
      'error_file_size',
      'error_validation',
      'ready',
      'getting_upload_params',
      'error_upload_params',
      'uploading',
      'exception_upload',
      'aborted',
      'error_upload',
      'headers_received',
      'done',
    ]).isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
    uploadedDate: PropTypes.string.isRequired,
    percent: PropTypes.number,
    size: PropTypes.number,
    lastModifiedDate: PropTypes.string,
    previewUrl: PropTypes.string,
    duration: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    videoWidth: PropTypes.number,
    videoHeight: PropTypes.number,
    validationError: PropTypes.any,
  }).isRequired,
  isUpload: PropTypes.bool.isRequired,
  canCancel: PropTypes.bool.isRequired,
  canRemove: PropTypes.bool.isRequired,
  canRestart: PropTypes.bool.isRequired,
  files: PropTypes.arrayOf(PropTypes.any).isRequired, // eslint-disable-line react/no-unused-prop-types
  extra: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    reject: PropTypes.bool.isRequired,
    dragged: PropTypes.arrayOf(PropTypes.any).isRequired,
    accept: PropTypes.string.isRequired,
    multiple: PropTypes.bool.isRequired,
    minSizeBytes: PropTypes.number.isRequired,
    maxSizeBytes: PropTypes.number.isRequired,
    maxFiles: PropTypes.number.isRequired,
  }).isRequired,
}

export default UploadPreview;
