import { CircularProgress, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import React, { useContext } from 'react';

import { CREATE_VARIATION_COMPONENT } from '@mutations/variations';
import CreativeSpaceContext from '@contexts/CreativeSpaceContext';
import ToolbarContext from '@contexts/ToolbarContext';
import { buildAppendFields } from '@schema/helpers';
import { useMutation } from '@apollo/client';
import withResourceDelete from '@components/withResourceDelete';

const StyledMenu = (props) => <Menu
  elevation={10}
  anchorEl={null}
  anchorOrigin={{
    vertical: 'center',
    horizontal: 'center',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'center',
  }}
  {...props}
/>

const DeleteResourceListItem = withResourceDelete(ListItemText)

const ContextMenu = ({ resource, anchorEl, onClose }) => {
  const { setSelectedLayer, setSelectedVariation, getSelectedSubcollection } = useContext(CreativeSpaceContext);
  const { setRightSidePanel } = useContext(ToolbarContext);
  const [createVariationComponent, { loading }] = useMutation(
    CREATE_VARIATION_COMPONENT,
    {
      update: (cache, { data: { createVariationComponent } }) => {
        cache.modify({
          id: cache.identify({
            id: getSelectedSubcollection()?.id,
            __typename: 'Subcollection',
          }),
          fields: buildAppendFields('layers', `Layer:${createVariationComponent.layer.id}`),
        });
      },
      onCompleted: ({ createVariationComponent }) => {
        setRightSidePanel(1); // Show the Variation Component preview
        setSelectedLayer(createVariationComponent.layer);
        setSelectedVariation(createVariationComponent, createVariationComponent.layer.id);
        onClose();
      },
    }
  );

  return (
    <StyledMenu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      {(() => {
        switch (resource.__typename) {
          case 'Variation':
            if (!resource.parentVariation) {
              return <MenuItem>
                <ListItemText
                  disabled={loading}
                  primary={
                    <Typography component="div" sx={{ textAlign: 'center' }}>
                      {loading
                        ? <CircularProgress disableShrink size={12} sx={{ ml: 1 }} />
                        : "Create a Variation Component"
                      }
                    </Typography>
                  }
                  onClick={() => {
                    createVariationComponent({
                      variables: { variationId: resource.id }
                    })
                  }} />
              </MenuItem>
            }
            break;
          default:
            break;
        }
      })()}
      <MenuItem>
        <DeleteResourceListItem
          resource={resource}
          primary={`Delete "${resource.name}"`} />
      </MenuItem>
    </StyledMenu>
  );
}

export default ContextMenu;