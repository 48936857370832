import React, { useEffect, useState } from 'react';
import { getCombinationDna, isResourceVariationComponent, isSuperSet, uniqueItems } from '@lib/helper';

import { CartesianProduct } from 'js-combinatorics';
import { filterLayers } from '@lib/merge-images';

const weight = (arr) => [].concat(...arr.map((obj) => {
  return Array(Math.ceil(obj.state.weight * 100)).fill(obj)
}));

const pick = (arr) => {
  let weighted = weight(arr);
  return weighted[Math.floor(Math.random() * weighted.length)]
}

const generateArray = (itemCount = 0) => Array.from(Array(itemCount).keys());

const multiPick = (arr, pickCount = 0) => {
  const numberOfPicks = generateArray(pickCount);
  let pickedItems = []
  numberOfPicks.forEach(() => {
    const listWithoutPicked = arr.filter((item) => !pickedItems.find((pickedItem) => pickedItem.id === item.id));
    pickedItems.push(pick(listWithoutPicked))
  })
  return pickedItems;
}

const sortById = (items, sortedItems) => items.sort((a, b) => sortedItems.indexOf(a.id) - sortedItems.indexOf(b.id));

const randomNumber = (min, max) => Math.round(Math.random() * (max - min) + min);

const pickLayers = ({ layers }) => {
  const filteredLayers = filterLayers(layers).filter(({ type }) => type !== "variation_component")
  const layerIdOrder = filteredLayers.map(layer => layer.id);
  const requiredLayers = filteredLayers.filter(layer => layer.state.required)
  const optionalLayers = filteredLayers.filter(layer => !layer.state.required)
  const pickCount = randomNumber(0, optionalLayers.length);
  const pickedLayers = multiPick(optionalLayers, pickCount)
  return sortById([
    ...requiredLayers,
    ...pickedLayers
  ], layerIdOrder)
}

const PreviewFilterContext = React.createContext({});

export default PreviewFilterContext;

const filterSubcollectionLayers = (subcollections) => subcollections.map(subcollection => ({
  ...subcollection,
  layers: subcollection.layers.filter(layer => !isResourceVariationComponent(layer))
}))

const groupByTraitName = (filterNames) => filterNames.reduce((acc, keyName) => {
  const split = keyName.split(':');
  const traitName = split[1];
  const variationName = split[2];
  const group = (acc[traitName] || []);
  group.push(keyName);
  acc[traitName] = group;
  return acc
}, {});

const getResourceId = (resource) => {
  switch (resource.__typename) {
    case 'Variation':
      return `${resource.__typename}:${resource.layer.name}:${resource.name}`
    case 'Subcollection':
      return `${resource.__typename}:${resource.id}:${resource.name}`
    default:
      return `${resource.__typename}:${resource.id}`
  }
}

const getInitialFilters = (resources) => resources.reduce((acc, resource) => ({ ...acc, [getResourceId(resource)]: false }), {})

const getResourceById = (resources) => resources.reduce((acc, resource) => ({ ...acc, [getResourceId(resource)]: resource }), {})

const cartesianProduct = (variationGroups, sampleSize) => {
  const combinations = new CartesianProduct(...variationGroups)
  const samples = [...new Array(sampleSize)].map(() => combinations.sample());
  return uniqueItems(samples, getCombinationDna)
}

const generateCombinationsBySubcollectionResource = (subcollections) => {
  const MAX_SAMPLE_COLLECTION_SIZE = 500;
  const sampleSize = Math.round(MAX_SAMPLE_COLLECTION_SIZE / subcollections.length)
  const combinationsBySubcollection = {}
  subcollections.forEach((subcollection) => {
    const variationGroups = pickLayers(subcollection).map(layer => layer.variations);
    if (variationGroups.length) {
      combinationsBySubcollection[getResourceId(subcollection)] = cartesianProduct(variationGroups, sampleSize)
    } else {
      combinationsBySubcollection[getResourceId(subcollection)] = []
    }
  })
  return combinationsBySubcollection;
}

export const PreviewFilterContextProvider = ({ children, subcollections, onCombinationReady, onFilterChange }) => {
  /*
    Filters Data Structure:
      {
        [variationName]: false
      }
  */
  const [filteredCombinations, setFilteredCombinations] = useState([])
  const [filteredSubcollections, setFilteredSubcollections] = useState([])
  const [resourceById, setResourceById] = useState({})
  const [selectedFilters, setSelectedFilters] = useState({});
  const [variationNamesByTraitName, setVariationNamesByTraitName] = useState({});
  const [combinationsByResource, setCombinationsByResource] = useState({});

  const removeFilter = (keyName) => {
    setSelectedFilters({
      ...selectedFilters,
      [keyName]: false
    })
  }

  const getFilterByResource = (resource) => selectedFilters[getResourceId(resource)];

  const getCombinationsByResource = (resource) => {
    switch (resource.__typename) {
      case 'Subcollection':
        return combinationsByResource[getResourceId(resource)];
      case 'Variation':
        return combinationsByResource[getResourceId(resource)];
      default:
        return [];
    }
  }

  const setFilterByResource = (resource, isChecked) => {
    setSelectedFilters({
      ...selectedFilters,
      [getResourceId(resource)]: isChecked
    })
  }

  const getVariationNamesByTraitNames = (resourceById) => {
    const variationNamesByTraitName = {}
    const uniqueVariations = Object.entries(resourceById)
      .filter(([keyName, resource]) => keyName.includes('Variation'))
      .map(([keyName, resource]) => resource);

    uniqueVariations.forEach(variation => {
      // Update Variations Dictionary
      const layer = resourceById[getResourceId(variation.layer)];
      if (!variationNamesByTraitName[layer.name]) {
        variationNamesByTraitName[layer.name] = []
      }
      variationNamesByTraitName[layer.name].push(variation.name)
    })
    return variationNamesByTraitName;
  }

  const getCombinationsByVariationResource = (combinations) => {
    let combinationsByVariationName = {}

    combinations.forEach((combination) => {
      combination.forEach(variation => {
        // Update Combinations Dictionary
        if (!combinationsByVariationName[getResourceId(variation)]) {
          combinationsByVariationName[getResourceId(variation)] = []
        }
        combinationsByVariationName[getResourceId(variation)].push(combination)
      })
    })

    return combinationsByVariationName;
  }

  const getAllCombinations = (combinationsBySubcollectionResource) => {
    const combinations = Object.values(combinationsBySubcollectionResource).flatMap(combinations => combinations);
    return uniqueItems(combinations, getCombinationDna)
  };

  const shuffleCombinations = () => {
    const processedSubcollections = filterSubcollectionLayers(subcollections);
    const combinationsBySubcollectionResource = generateCombinationsBySubcollectionResource(processedSubcollections);
    const layers = processedSubcollections.flatMap(subcollection => subcollection.layers);
    const combinations = getAllCombinations(combinationsBySubcollectionResource)
    const variations = combinations.flatMap(combination => combination);

    setFilteredSubcollections(processedSubcollections)

    setCombinationsByResource({
      ...combinationsBySubcollectionResource,
      ...getCombinationsByVariationResource(combinations)
    })

    setResourceById({
      ...resourceById,
      ...getResourceById(processedSubcollections),
      ...getResourceById(layers),
      ...getResourceById(variations)
    })

    setSelectedFilters({
      ...getInitialFilters(processedSubcollections),
      ...getInitialFilters(variations),
      ...selectedFilters // This last will allow shuffle to retain the selected filters
    })
  };

  const filterCombinations = (selectedFilters) => {
    let combinations = Object.values(combinationsByResource).flatMap(v => v);
    if (!selectedFilters) {
      return combinations;
    }

    const checkedFilters = Object.entries(selectedFilters).filter(([name, isChecked]) => isChecked).flatMap(([name]) => name)
    if (checkedFilters.length === 0) {
      return combinations;
    }

    // When filtering the Subcollections, this is a logical "OR"
    const subcollectionFilters = checkedFilters.filter(filterName => filterName.split(':')[0] === 'Subcollection')
    if (subcollectionFilters.length > 0) {
      combinations = Object.entries(combinationsByResource)
        .filter(([resourceId, combinations]) => subcollectionFilters.includes(resourceId))
        .flatMap(([resourceId, combinations]) => combinations)

    }
    combinations = uniqueItems(combinations, getCombinationDna)

    // When filtering the Variations, this is a logical "AND"
    // Logical OR for same layer, but logical AND between layers
    const variationFilters = checkedFilters.filter(filterName => filterName.split(':')[0] === 'Variation')

    const groupedVariationNames = Object.values(groupByTraitName(variationFilters));
    let attributeCombinations = [...new CartesianProduct(...groupedVariationNames)];

    return combinations.filter(combination => {
      const keyNames = combination.map(variation => getResourceId(variation));
      return attributeCombinations.find(attributeCombination => isSuperSet(keyNames, attributeCombination))
    })
  }

  useEffect(shuffleCombinations, [subcollections])


  useEffect(() => {
    const combinations = filterCombinations(selectedFilters);
    setFilteredCombinations(combinations);
    onCombinationReady(combinations)
  }, [selectedFilters])


  useEffect(() => {
    setVariationNamesByTraitName(getVariationNamesByTraitNames(resourceById))
  }, [resourceById])

  let context = {
    filteredCombinations,
    variationNamesByTraitName,
    selectedFilters,
    filteredSubcollections,
    getCombinationsByResource,
    getFilterByResource,
    setFilterByResource,
    removeFilter,
    shuffleCombinations
  }

  return (
    <PreviewFilterContext.Provider value={context}>
      {children}
    </PreviewFilterContext.Provider>
  );
};

PreviewFilterContextProvider.defaultProps = {
  subcollections: [],
}

export const { Consumer: PreviewFilterContextConsumer } = PreviewFilterContext;
