import { gql } from '@apollo/client';

const PRESIGN_URL = gql`
  query uploadUrl($collectionId: ID!, $layerId: ID!, $fileName: String!) {
    uploadUrl(collectionId: $collectionId, layerId: $layerId, fileName: $fileName) {
      url
    }
  }
`;

export { PRESIGN_URL };
