import { gql } from '@apollo/client';

const REVOKE_COLLABORATOR_ACCESS = gql`
  mutation RevokeCollaboratorAccess($collectionId: ID!, $collaboratorId: ID!) {
    revokeCollaboratorAccess(collectionId: $collectionId, collaboratorId: $collaboratorId) {
      id
    }
  }
`;

export {
  REVOKE_COLLABORATOR_ACCESS
};
