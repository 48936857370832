import { Box, Button, Chip, Divider, Paper, Tab, Tabs, Tooltip } from '@mui/material';
import { Link, navigate } from 'gatsby';
import React, { useContext } from 'react';
import { Redirect, useLocation, useMatch } from '@reach/router';

import CollectionEditor from '@components/CollectionEditor';
import { Content } from '@components/Layout';
import CreativeSpaceContext from '@contexts/CreativeSpaceContext';
import EditableResourceName from '@components/EditableResourceName';
import { HomeButton } from '@components/Buttons';
import ShareModal from '@components/ShareModal';
import StyledRouter from '@components/StyledRouter';
import { ToolbarProvider } from '@contexts/ToolbarContext';
import VariationsEditor from '@components/VariationsEditor';
import { WindowSpace } from '@components/Layout';

const VariationsEditorDecorated = () => <Content>
  <ToolbarProvider>
    <VariationsEditor />
  </ToolbarProvider>
</Content>;

const CollectionEditorDecorated = () => <Content>
  <ToolbarProvider>
    <CollectionEditor />
  </ToolbarProvider>
</Content>;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const EditorLayout = ({ children }) => {
  return (
    <Paper elevation={3} sx={{ boxShadow: 'none' }}>
      <WindowSpace>{children}</WindowSpace>
    </Paper>
  );
};

const EditorHeader = (props) => {
  return (
    <Paper elevation={5} sx={{ mb: 1 }}>
      <Box
        sx={{
          minHeight: 50,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {props.children}
      </Box>
    </Paper>
  );
};

const EditorHeaderItem = (props) => (
  <Box {...props} sx={{ flexGrow: 1, ...(props.sx ? props.sx : {}) }} />
);

const CreativeSpace = () => {
  const { collection } = useContext(CreativeSpaceContext);

  const location = useLocation();
  const match = useMatch("/collections/:id/*");
  const collectionMatch = useMatch("/collections/:id/collection/*");

  const tabStyles = {
    p: 1.5,
    fontSize: '0.8rem',
  };

  return (
    <EditorLayout>
      <EditorHeader>
        <EditorHeaderItem
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexBasis: 0,
          }}
        >
          <HomeButton onClick={() => navigate('/collections')} sx={{ mr: 1 }} />
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ mr: 2 }}
          />

          <Tabs
            value={location.pathname}
            aria-label="Creative Tabs"
          >
            <Tab
              label="Editor"
              // This is a hack to prevent MUI from throwing since it doesn't have a way to allow multiple matches
              // Ex, the tab will be selected if the path is `/collection/:id` or `collection/:id/editor`
              value={match['*'] === '' ? `${match.uri}` : `${match.uri}/editor`}
              to={`${match.uri}/editor`}
              component={Link}
              {...a11yProps(0)}
              sx={tabStyles} />
            <Tab
              label="Build"
              value={collectionMatch ? `${location.pathname}` : `${match.uri}/collection`}
              to={`${match.uri}/collection`}
              component={Link}
              {...a11yProps(1)}
              sx={tabStyles}
            />
          </Tabs>
        </EditorHeaderItem>
        <EditorHeaderItem sx={{ textAlign: 'center' }}>
          <EditableResourceName
            inputRef={undefined}
            resource={collection}
            sx={{
              width: 'initial',
              input: {
                textAlign: 'center'
              }
            }}
            noFocusBorder={false}
            disabled={false} />
        </EditorHeaderItem>
        <EditorHeaderItem sx={{ textAlign: 'right', pr: 1 }}>
          {collection.isSharedCollection &&
            // Get the current collaborator then display this message by role
            <Tooltip title={"You are a Viewer of this collection. All your changes are only local and will reset on refresh."}>
              <Chip color="warning" size="small" label="You are a Viewer" />
            </Tooltip>}

          <Button
            href="https://gold-error-e2d.notion.site/GENGEN-Knowledge-Base-03640dfcd1bf4f299f7ed7e2f25b810f"
            target="_blank"
            sx={{ mr: 1 }}>
            Help
          </Button>
          <ShareModal collection={collection} />
        </EditorHeaderItem>
      </EditorHeader>
      <StyledRouter>
        <CollectionEditorDecorated path="collection/*" />
        <VariationsEditorDecorated path="editor" />
        <Redirect
          from="/"
          to="editor"
          noThrow
        />
      </StyledRouter>
    </EditorLayout>
  );
};

export default CreativeSpace;
