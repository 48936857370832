import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";

import mergeImages from 'merge-images';
import { uniqueItems } from '@lib/helper';

let ffmpeg = undefined;
const getFfmpeg = async () => {
  if (!ffmpeg) {
    ffmpeg = createFFmpeg({ log: process.env.NODE_ENV !== 'production', corePath: "https://unpkg.com/@ffmpeg/core@0.10.0/dist/ffmpeg-core.js" });
  }

  if (!ffmpeg.isLoaded()) {
    await ffmpeg.load();
  }

  return ffmpeg;
}

const buildCommand = (input, command, output) => [input, command, output].filter(value => value).join('')
const chainCommands = (commands) => commands.join(',')
const combineCommands = (commands) => commands.join(';')

const getCanvasFunction = (input, output, width, height) => buildCommand(
  input,
  /* 
    "scale,format=rgba" preserves the transparency. Without it, when the canvas expands
    then it will create black bars. 
  */
  `scale,format=rgba,pad=width=${width}:height=${height}:x=0:y=0:color=black@0.0`,
  output,
)

const getPaletteGenFunction = (input, output) => buildCommand(
  input,
  "palettegen",
  output
)
const getPaletteUseFunction = (input1, input2) => buildCommand(
  chainInputs([input1, input2]),
  "paletteuse"
)
const getSplitFunction = (output1, output2) => buildCommand(
  "split",
  chainOutputs([output1, output2])
)
const getInput = (inputRef) => `[${inputRef}]`
const getOutput = (outputRef) => `[${outputRef}]`
const chainInputs = (inputs) => inputs.join('')
const chainOutputs = (outputs) => outputs.join('')
const getOverlayFunction = (input1, input2, output) => buildCommand(
  chainInputs([input1, input2]),
  "overlay=format=auto",
  output
)

// Ex, '-filter_complex', '[0:v][1:v]overlay=format=auto[out];[out][2:v]overlay=format=auto,split[s0][s1];[s0]palettegen[p];[s1][p]paletteuse',
const getCommands = (files, width, height) => {
  let lastOutput = "";

  const overlayCommands = files.flatMap((file, index, array) => {
    const input = getInput(`${index}:v`);
    let output;
    let command;
    // Overlay requires 2 arguments to overlay
    if (index === 0) {
      output = getOutput('padded');
      command = getCanvasFunction(input, output, width, height)
    } else if (index === array.length - 1) {
      // undefined output means it expects some chaining (ex, it will be followed by a command and some function)
      command = getOverlayFunction(lastOutput, input, undefined)
    } else {
      output = getOutput(`output${index}`);
      command = getOverlayFunction(lastOutput, input, output)
    }

    lastOutput = output;
    return command;
  })

  const splitOutput1 = getOutput("s0");
  const splitOutput2 = getOutput("s1");
  const paletteGenOuput = getOutput("p");
  return [
    chainCommands([
      combineCommands(overlayCommands),
      getSplitFunction(splitOutput1, splitOutput2)
    ]),
    getPaletteGenFunction(splitOutput1, paletteGenOuput),
    getPaletteUseFunction(splitOutput2, paletteGenOuput)
  ]
}

const getFilterComplex = (files, width, height) => {
  if (files.length === 1) {
    return [];
  }

  return [
    "-filter_complex",
    combineCommands(getCommands(files, width, height))
  ];
}

const getSourceInputs = (files) => files.flatMap((file) => ['-i', file.name]);

export const mergeGifCombination = async (files, width, height) => {
  const uniqueFiles = uniqueItems(files, 'name');
  const hasGif = uniqueFiles.find(files => files.mimeType === 'image/gif');
  const ffmpeg = await getFfmpeg();

  for (const file of uniqueFiles) {
    await ffmpeg.FS('writeFile', file.name, await fetchFile(file.url));
  }

  let sourceInputs = getSourceInputs(uniqueFiles);
  if (hasGif) {
    sourceInputs = [
      // TODO: When FFMPEG fixes oom issues, look into this to fix the multiple layer gif issues
      // https://stackoverflow.com/questions/30846719/infinite-stream-from-a-video-file-in-a-loop
      // '-fflags', '+genpts', // will regenerate the pts timestamps so it loops smoothly,
      // '-stream_loop', '-1',
      ...sourceInputs
    ]
  }

  await ffmpeg.run(
    ...sourceInputs,
    ...getFilterComplex(uniqueFiles, width, height),
    'preview.gif'
  );

  const data = ffmpeg.FS('readFile', 'preview.gif');
  ffmpeg.FS('unlink', 'preview.gif');
  uniqueFiles.forEach((file) => ffmpeg.FS('unlink', file.name));
  return URL.createObjectURL(new Blob([data.buffer], { type: 'image/gif' }));
}

export const mergeCombination = async (files, width, height) => {
  const options = {
    crossOrigin: 'anonymous',
    width,
    height
  }

  const imageSources = files.map(file => ({ src: file.url }))
  return mergeImages(imageSources, options).then((b64) => b64 === 'data:,' ? undefined : b64)
}

export const filterLayers = (layers) => layers
  .filter(layer => layer.state.visibility)
  .filter(layer => layer.variations.length > 0)

const subcollectionCombinationCount = (subcollection) => {
  const combinationCountByLayerId = {}
  const layers = filterLayers(subcollection.layers)
  layers.forEach(layer => {
    if (!combinationCountByLayerId[layer.id]) {
      if (!layer.state.required) {
        combinationCountByLayerId[layer.id] = layer.variations.length + 1
      } else {
        combinationCountByLayerId[layer.id] = layer.variations.length;
      }
    }
  })

  return Object.values(combinationCountByLayerId).reduce((acc, count) => acc * count, 1)
}

export const totalCombinationCount = collection => {
  const combinationCountBySubcollectionId = {}
  collection.subcollections.forEach(subcollection => {
    if (!combinationCountBySubcollectionId[subcollection.id]) {
      combinationCountBySubcollectionId[subcollection.id] = subcollectionCombinationCount(subcollection)
    }
  })
  return Object.values(combinationCountBySubcollectionId).reduce((acc, count) => acc * count, 1)
}
