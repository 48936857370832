import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import React, { useState } from 'react';
import { getResourceColor, isResourceVariationComponent } from '@lib/helper';

import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { ContextMenu } from '@components/ContextMenus';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditableResourceName from '@components/EditableResourceName';
import SquareIconButton from '@components/SquareIconButton';
import { ToggleLayerVisibilityTool } from '@components/VariationsEditorToolbar';

const Layer = (props) => {
  const {
    isDragging,
    isOverlay,
    sortableRef,
    sortableStyles,
    sortableAttributes,
    sortableListeners,
    selected,
    indentationPadding,
    layer,
    depth,
    onSelect,
  } = props;

  const isOverlayStyle = {
    ml: 0,
    mt: 0,
    display: 'inline-flex',
  };

  const isDraggingStyle = {
    border: 1,
    opacity: 0.5,
  };

  return (
    <LayerLayout
      layer={layer}
      selected={selected}
      onSelect={onSelect}
      sortableRef={sortableRef}
      sortableStyles={sortableStyles}
      sortableAttributes={sortableAttributes}
      sortableListeners={sortableListeners}
      sx={{
        ml: depth * indentationPadding,
        ...(isOverlay ? isOverlayStyle : {}),
        ...(isDragging ? isDraggingStyle : {}),
      }}
    >
      {
        layer.type === 'variation_component'
          ?
          <Typography sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
            {layer.name}
            <AutoAwesomeMotionIcon fontSize="small" sx={{ mr: 0.5 }} />
          </Typography>
          :
          <NormalLayerType layer={layer} isOverlay={isOverlay} />
      }
    </LayerLayout>
  );
};

const NormalLayerType = ({ layer, isOverlay }) => {
  const [editMode, setEditMode] = useState(false);

  const overlayHiddenStyle = {
    ...(isOverlay ? { display: 'none' } : {}),
  };

  return (
    <React.Fragment>
      <CardContent sx={{ display: 'flex', p: 0.5, pt: 0.7, pl: 0, width: '100%', alignItems: 'center' }}>
        <EditableResourceName resource={layer} onEditModeChange={setEditMode} />
        {!editMode && <Typography component="span" color="action.disabled">
          ({layer.variations.length})
        </Typography>}
      </CardContent>
      {!editMode &&
        <ToggleLayerVisibilityTool
          layer={layer}
          sx={{
            ml: 0.5,
            p: 0.5,
            ...overlayHiddenStyle
          }} />
      }
    </React.Fragment>
  )
}

const DraggableCardActionArea = ({ isDragging, sortableAttributes, sortableListeners, sx }) => {
  return (
    <SquareIconButton
      sx={{
        cursor: isDragging ? 'grabbing' : 'grab',
        p: 0,
        pt: 0.5,
        pb: 0.5,
        mr: 1,
        ...sx
      }}
      {...sortableAttributes}
      {...sortableListeners}
    >
      <DragIndicatorIcon fontSize="medium" />
    </SquareIconButton>
  )
}

const LayerLayout = ({ layer, selected, children, sx, onSelect, isDragging, sortableRef, sortableStyles, sortableAttributes, sortableListeners }) => {
  const isVariationComponent = isResourceVariationComponent(layer);
  const variantComponentStyle = {
    borderColor: getResourceColor(layer),
    color: getResourceColor(layer),
  }

  const variationComponentOverrides = {
    ...(isVariationComponent ? variantComponentStyle : {})
  }

  const layerStyle = {
    display: 'flex',
    flexGrow: 1,
    pl: 0,
    mb: 1,
    height: 42,
    borderLeftWidth: 2,
    borderLeftStyle: 'solid',
    borderLeftColor: 'primary.main',
    ...variationComponentOverrides,
    ...(isVariationComponent ? { height: 30, borderRadius: 1, borderLeftColor: getResourceColor(layer) } : {})
  };

  const selectedStyle = {
    border: 2,
    borderColor: 'primary.main',
    ...variationComponentOverrides,
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card
      elevation={6}
      sx={{
        ...layerStyle,
        ...sortableStyles,
        ...(selected ? selectedStyle : {}),
        ...sx,
      }}
      ref={sortableRef}
    >
      <CardActionArea
        component="a"
        onClick={onSelect}
        onContextMenu={handleClick}
        sx={{ display: 'flex', flexDirection: 'row', pl: 0.5, pr: 0.5, justifyContent: 'left' }}>
        <DraggableCardActionArea
          isDragging={isDragging}
          sortableAttributes={sortableAttributes}
          sortableListeners={sortableListeners}
          sx={variationComponentOverrides} />
        {children}
      </CardActionArea>
      <ContextMenu
        key={layer.id}
        resource={layer}
        anchorEl={anchorEl}
        onClose={handleClose} />
    </Card>
  )
}

export default Layer;

Layer.defaultProps = {
  isOverlay: false,
  isDragging: false,
  sortableRef: undefined,
  sortableStyles: {},
  sortableAttributes: {},
  sortableListeners: {},
  layer: {},
  selected: false,
  depth: 0,
  indentationPadding: 5,
  visibility: true,
  onSelect: () => { },
};
