import { Badge, Box } from '@mui/material';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import React, { useEffect, useMemo, useState } from 'react';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  buildTree,
  flattenTree,
  getDescendentsCount,
  getDescendentsForFlatTrees
} from './tree.js'

import Layer from '@components/Layer';
import PropTypes from 'prop-types';
import SortableLayer from '@components/SortableLayer';

const GhostOverlayLayer = ({ layer, badgeCount }) => {
  return (
    <Box>
      <Badge color="secondary" badgeContent={badgeCount}>
        <Layer
          key={layer.id}
          id={layer.id}
          value={layer.id}
          isOverlay={true}
          layer={layer}
        />
      </Badge>
    </Box>
  );
};

/*
    By using using state to store the original data structure with children, it has an implicit UX effect. When you move
    parents with children, it automatically moves the whole group together. This was not the case when storing the state
    with the flattened items. The reason this happens is because the flattenTree function is re-run in items change thus
    cascasing a change in the render.
*/
const Layers = ({ layers, selectedLayerId, onPositionChange, onSelect }) => {

  // Sortable
  const indentationPadding = 5;
  const layoutMeasuring = { strategy: MeasuringStrategy.Always };

  // Notes: localLayers requires the `children` key to help maintain the sort order.
  const [localLayers, setLocalLayers] = useState(layers);
  const [activeLayerId, setActiveLayerId] = useState(null);
  const [activeLayer, setActiveLayer] = useState({});
  const [activeXOffset, setActiveXOffset] = useState(0);
  const [overLayerId, setOverLayerId] = useState(null);

  useEffect(() => {
    const layersOrderedByPosition = layers
      .map((layer) => layer)
      .sort((layerA, layerB) => layerA.state.position - layerB.state.position);
    setLocalLayers(layersOrderedByPosition)
  }, [layers]);

  const memoizedLayers = useMemo(() => {
    /*
        In order for the minDepth and maxDepth of a given active item to be accurate, the descendents of the current item
        must be removed from the list. Otherwise, this leads to weird UI situations where it jumps to an unexpected depth.

        This also has the UX effect of "collapsing" the descendents to help visually move things more easily.
    */
    if (activeLayerId) {
      const descendents = getDescendentsForFlatTrees(
        localLayers,
        activeLayerId
      );
      const descendentIds = descendents.map((item) => item.id);
      return localLayers.filter(
        (item) => descendentIds.indexOf(item.id) === -1
      );
    }
    return localLayers;
  }, [activeLayerId, localLayers]);

  const getProjectedParentId = (items, previousItem, depth, overItemIndex) => {
    if (depth === 0 || !previousItem) {
      return null;
    }

    if (depth === previousItem.state.depth) {
      return previousItem.state.parentId;
    }

    if (depth > previousItem.state.depth) {
      return previousItem.id;
    }

    const newParent = items
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.state.depth === depth);

    return newParent?.state.parentId ?? null;
  };

  /*
      This gets the current projected depth with the consideration of it's the maximum and minimum depth
      possible for a given projection.

      Ex, if the previous item is it's parent then it's max depth is already has been reached.
      Ex, if the previous item is it's sibling then it's can be nested into the sibling.
      Ex, if the next item is a sibling then it's min depth has already been reached.

      etc...
  */
  const getProjectedDepth = (
    currentItem,
    previousItem,
    nextItem,
    activeXOffset,
    indentationPadding
  ) => {
    // Disable nesting layers
    return 0;

    // TODO: Revisit this if we ever decide to using nesting. 
    // const sensitivity = indentationPadding * 10; // this was calibrated manually

    // const maxDepth = getMaxDepth(previousItem);
    // const minDepth = getMinDepth(nextItem);
    // const hypotheticalDepth =
    //   currentItem.state.depth + Math.round(activeXOffset / sensitivity);
    // let depth = hypotheticalDepth;

    // if (hypotheticalDepth >= maxDepth) {
    //   depth = maxDepth;
    // } else if (hypotheticalDepth < minDepth) {
    //   depth = minDepth;
    // }

    // return depth;

    // function getMinDepth(nextItem) {
    //   if (nextItem) {
    //     return nextItem.state.depth;
    //   }
    //   return 0;
    // }

    // function getMaxDepth(previousItem) {
    //   if (previousItem) {
    //     return previousItem.state.depth + 1;
    //   }
    //   return 0;
    // }
  };

  const getProjectedPlacement = (
    items,
    activeLayerId,
    overLayerId,
    activeXOffset,
    indentationPadding
  ) => {
    const overItemIndex = items.findIndex((item) => item.id === overLayerId);
    const activeItemIndex = items.findIndex(
      (item) => item.id === activeLayerId
    );
    const currentItem = items[activeItemIndex];

    // You want to move the items in memory so your UI can show the depth it is capable of.
    const hypotheticalItems = arrayMove(items, activeItemIndex, overItemIndex);
    const previousItem = hypotheticalItems[overItemIndex - 1];
    const nextItem = hypotheticalItems[overItemIndex + 1];
    const projectedDepth = getProjectedDepth(
      currentItem,
      previousItem,
      nextItem,
      activeXOffset,
      indentationPadding
    );

    return {
      depth: projectedDepth,
      parentId: getProjectedParentId(
        hypotheticalItems,
        previousItem,
        projectedDepth,
        overItemIndex
      ),
    };
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const resetDragOverlayState = () => {
    setActiveLayerId(null);
    setActiveLayer({});
    setOverLayerId(null);
  };
  const handleDragStart = (event) => {
    setActiveLayerId(event.active.id);
    setActiveLayer(memoizedLayers.find((item) => item.id === event.active.id));
    setOverLayerId(event.active.id);
  };
  const handleDragMove = (event) => {
    setActiveXOffset(event.delta.x);
  };
  const handleDragOver = (event) => {
    setOverLayerId(event.over?.id ?? null);
  };
  const handleDragEnd = (event) => {
    resetDragOverlayState();

    const { active, over } = event;
    const projectedPlacement =
      active && over
        ? getProjectedPlacement(
          memoizedLayers,
          activeLayerId,
          overLayerId,
          activeXOffset,
          indentationPadding
        )
        : null;

    if (projectedPlacement && over) {
      const { depth, parentId } = projectedPlacement;

      const activeIndex = localLayers.findIndex(
        (layer) => layer.id === active.id
      );
      const overIndex = localLayers.findIndex((layer) => layer.id === over.id);
      const activeItem = localLayers[activeIndex];
      const overItem = localLayers[overIndex];

      // Get the projected and update the active item to rebase under the new parent
      let updatedLayer = {
        ...activeItem,
        state: {
          ...activeItem.state,
          depth,
          parentId,
        },
      };

      let updatedLayers = [
        ...localLayers.slice(0, activeIndex),
        updatedLayer,
        ...localLayers.slice(activeIndex + 1),
      ];

      // Local update 
      updatedLayers = arrayMove(updatedLayers, activeIndex, overIndex).map((layer, index) => ({
        ...layer,
        state: {
          ...layer.state,
          position: index + 1
        }
      }))

      /*
          I didn't want to figure out how to move the descendents with the parents, so
          it rebuilds the tree and then reflattens it. This process is wasteful, I know.
      */
      onPositionChange(updatedLayers, updatedLayer, overItem.state.position)
      setLocalLayers(
        flattenTree(buildTree(updatedLayers))
      );
    }
  };
  const handleDragCancel = (event) => {
    resetDragOverlayState();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        layoutMeasuring={layoutMeasuring}
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <SortableContext
          items={memoizedLayers.map((layer) => layer.id)}
          strategy={verticalListSortingStrategy}
        >
          {memoizedLayers.map((layer, index) => {
            const { id, state: { depth } } = layer;
            const projectedPlacement =
              id === activeLayerId
                ? getProjectedPlacement(
                  memoizedLayers,
                  activeLayerId,
                  overLayerId,
                  activeXOffset,
                  indentationPadding
                )
                : null;
            return (
              <SortableLayer
                key={id}
                id={id}
                value={id}
                selected={selectedLayerId === layer.id}
                onClick={() => {
                  onSelect(layer);
                }}
                layer={layer}
                index={index}
                depth={
                  id === activeLayerId && overLayerId
                    ? projectedPlacement.depth
                    : depth
                }
                indentationPadding={indentationPadding}
              />
            );
          })}
        </SortableContext>
        <DragOverlay>
          {activeLayerId && activeLayer ? (
            <GhostOverlayLayer
              layer={activeLayer}
              badgeCount={
                getDescendentsCount(localLayers, activeLayerId) > 1
                  ? getDescendentsCount(localLayers, activeLayerId)
                  : undefined
              }
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </Box>
  );
};

export default Layers;

Layers.propTypes = {
  layers: PropTypes.array.isRequired,
  selectedLayerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onPositionChange: PropTypes.func,
  onSelect: PropTypes.func,
};
