import { weightToRarity, weightToRarityColor } from '@lib/helper'

import React from 'react';
import { Typography } from '@mui/material';

const Rarity = ({ weight }) =>
    <Typography component="span" sx={{ color: weightToRarityColor(weight) }}>
        {weightToRarity(weight)}
    </Typography>

export default Rarity;