import {
  AppBar,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Toolbar,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import NftCombination from './NftCombination';
import { getCombinationDna } from '@lib/helper';

const NftCombinations = ({ combinations, selectedCombination, onSelect }) => {
  const [perPage, setPerPage] = useState(15);
  const [page, setPage] = useState(1);
  const onPageChange = (event, newPage) => setPage(newPage)

  const currentPageCombinations = useMemo(() => {
    const startIndex = (page - 1) * perPage;
    const endINdex = (page * perPage);
    return combinations.slice(startIndex, endINdex)
  }, [combinations, page, perPage])

  useEffect(() => setPage(1), [combinations])

  const updatePerPage = (event) => {
    setPerPage(event.target.value);
    setPage(1)
  };

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        position: 'relative',
        alignContent: 'flex-start',
        pl: 1,
        pr: 1
      }} >
        {combinations.length > 0 ?
          currentPageCombinations.map((combination) =>
            <NftCombination
              key={getCombinationDna(combination)}
              selected={getCombinationDna(selectedCombination) === getCombinationDna(combination)}
              combination={combination}
              onSelect={onSelect} />
          )
          :
          <Paper sx={{ p: 1 }}>
            There are no combinations
          </Paper>}
      </Box>
      <AppBar position='sticky' sx={{ bottom: 0 }}>
        <Toolbar>
          <Pagination
            sx={{ flexGrow: 1 }}
            count={Math.ceil(combinations.length / perPage)}
            page={page}
            onChange={onPageChange}
            siblingCount={5}
            boundaryCount={5}
            shape="rounded" />
          <FormControl sx={{ m: 1, minWidth: 80 }}>
            <InputLabel id="demo-simple-select-autowidth-label">Per Page</InputLabel>
            <Select
              value={perPage}
              onChange={updatePerPage}
              autoWidth
              label="Per Page"
            >
              <MenuItem value={15}>15</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

NftCombinations.defaultProps = {
  selectedCombination: undefined,
  combinations: [],
  onSelect: () => { }
}

export default NftCombinations;
