import React, { useState } from 'react';

import FocusBlurTextField from '@components/FocusBlurTextField';
import { UPDATE_COLLECTION_NAME } from '@mutations/collections';
import { UPDATE_LAYER_NAME } from '@mutations/layers';
import { UPDATE_SUBCOLLECTION_NAME } from '@mutations/subcollections';
import { UPDATE_VARIATION_NAME } from '@mutations/variations';
import { useMutation } from '@apollo/client';

const EditableResourceName = (props) => {
  const { resource, onEditModeChange, sx, ...rest } = props;
  const [editMode, setEditMode] = useState(false);
  const [updateCollectionName] = useMutation(UPDATE_COLLECTION_NAME);
  const [updateSubcollectionName] = useMutation(UPDATE_SUBCOLLECTION_NAME);
  const [updateLayerName] = useMutation(UPDATE_LAYER_NAME);
  const [updateVariationName] = useMutation(UPDATE_VARIATION_NAME);

  const updateResourceName = (resource, name) => {
    switch (resource.__typename) {
      case 'Collection':
        updateCollectionName({
          variables: {
            collectionId: resource.id,
            name
          },
        })
        break;
      case 'Subcollection':
        updateSubcollectionName({
          variables: {
            subcollectionId: resource.id,
            name
          },
        })
        break;
      case 'Layer':
        updateLayerName({
          variables: {
            layerId: resource.id,
            name
          },
        })
        break;
      case 'Variation':
        updateVariationName({
          variables: {
            variationId: resource.id,
            name
          },
        })
        break;
      default:
        throw new Error(`Updating name not supported for type ${resource.__typename}`)
    }
  }

  const onClick = (event) => {
    switch (event.detail) {
      case 2:
        setEditMode(true)
        onEditModeChange(true)
        event.stopPropagation();
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  return (
    <FocusBlurTextField
      noFocusBorder={true}
      sx={{
        width: '100%',
        ...(editMode ? { backgroundColor: "#2A2A2A" } : {}),
        '.MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: '#ffffff',
          cursor: 'pointer',
        },
        ...sx
      }}
      inputRef={input => input && input.focus()}
      disabled={!editMode}
      onClick={onClick}
      initialText={resource?.name}
      onBlur={(event, name) => {
        setEditMode(false)
        onEditModeChange(false)
        updateResourceName(resource, name)
      }}
      {...rest}
    />
  )
}

EditableResourceName.defaultProps = {
  resource: undefined,
  onEditModeChange: () => { },
};

export default EditableResourceName;