import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useContext } from 'react';
import { getCombinationDna, isResourceVariationComponent, sortVariationsByLayerId } from '@lib/helper';

import CreativeSpaceContext from '@contexts/CreativeSpaceContext';
import EditableResourceName from '@components/EditableResourceName';
import EditableResourceState from '@components/EditableResourceState';
import LabelStack from '@components/LabelStack';
import { NftCombination } from '@components/NftCombinations';
import { PersistSelectWeightState } from '@components/SelectWeightState';
import { PersistToggleRequiredState } from '@components/ToggleRequiredState';
import { RightColumn } from '@components/Layout';
import { ToolbarConsumer } from '@contexts/ToolbarContext';

const EditorDetails = () => {
  const {
    collection,
    getSelectedSubcollection,
    getSelectedLayer,
    getSelectedVariation,
    getNftPreviewImageSources,
  } = useContext(CreativeSpaceContext);
  const selectedSubcollection = getSelectedSubcollection();
  const selectedLayer = getSelectedLayer();
  const selectedVariation = getSelectedVariation();
  const variations = getNftPreviewImageSources();

  return <Box>
    <NftCombination
      key={getCombinationDna(variations)}
      gifPreviewControl={true}
      minWidth={350}
      maxWidth={350}
      combination={variations} />

    <List
      sx={{ width: '100%' }}
      subheader={<ListSubheader>Image Canvas Dimensions</ListSubheader>}
    >
      <ListItem>
        <ListItemText primary="Width (px)" />
        <EditableResourceState
          inputRef={undefined}
          resource={collection}
          sx={{
            width: 'auto',
            '& input': {
              textAlign: 'right'
            }
          }}
          noFocusBorder={false}
          disabled={false}
          initialText={collection.state.imageWidth}
          onNextState={(resource, newWidth) => ({ ...resource.state, imageWidth: parseInt(newWidth) })}
          numbersOnly={true}

        />
      </ListItem>
      <ListItem>
        <ListItemText primary="Height (px)" />
        <EditableResourceState
          inputRef={undefined}
          resource={collection}
          sx={{
            width: 'auto',
            '& input': {
              textAlign: 'right'
            }
          }}
          noFocusBorder={false}
          disabled={false}
          initialText={collection.state.imageHeight}
          onNextState={(resource, newHeight) => ({ ...resource.state, imageHeight: parseInt(newHeight) })}
          numbersOnly={true}
        />
      </ListItem>
    </List>

    <List
      sx={{ width: '100%' }}
      subheader={<ListSubheader>Subcollection</ListSubheader>}
    >
      <ListItem>
        <ListItemText primary="Rarity" />
        <PersistSelectWeightState
          resource={selectedSubcollection}
          initialWeightState={selectedSubcollection?.state.weight} />
      </ListItem>
    </List>

    <List
      sx={{ width: '100%' }}
      subheader={<ListSubheader>Layer</ListSubheader>}
    >
      {selectedLayer
        ?
        <React.Fragment>
          <ListItem>
            <ListItemText primary="Required" />
            <PersistToggleRequiredState resource={selectedLayer} initialRequiredState={selectedLayer?.state.required} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Rarity" />
            <PersistSelectWeightState
              resource={selectedLayer}
              initialWeightState={selectedLayer?.state.weight}
              disabled={selectedLayer?.state.required} />
          </ListItem>
        </React.Fragment>
        :
        <ListItem>Please create a layer</ListItem>
      }
    </List>
    <List
      sx={{ width: '100%' }}
      subheader={<ListSubheader>Variation</ListSubheader>}
    >
      {(selectedLayer && selectedVariation)
        ?
        <React.Fragment>
          <ListItem>
            <ListItemText primary="Rarity" />
            <PersistSelectWeightState
              resource={selectedVariation}
              initialWeightState={selectedVariation?.state.weight} />
          </ListItem>
          <ListItem>
            <ListItemText primary={
              <Tooltip title="This will show up in the traits metadata and is initially set to the file name." enterDelay={500} >
                <Typography>Name</Typography>
              </Tooltip>
            } />
            <EditableResourceName
              inputRef={undefined}
              resource={selectedVariation}
              sx={{
                width: 'auto',
                '& input': {
                  textAlign: 'right'
                }
              }}
              noFocusBorder={false}
              disabled={false} />
          </ListItem>
          <ListItem>
            <ListItemText primary="File Name" />
            <Typography component="span" color="action.disabled">{selectedVariation?.file?.name}</Typography>
          </ListItem>
        </React.Fragment>
        :
        <ListItem>Please upload a variation</ListItem>
      }
    </List>
  </Box>
}

const VariationComponentDetails = () => {
  const {
    getSelectedLayer,
    getSelectedVariation,
  } = useContext(CreativeSpaceContext);
  const selectedLayer = getSelectedLayer();
  const isLayerVariationComponent = isResourceVariationComponent(selectedLayer)
  const selectedVariation = getSelectedVariation();
  const isSelectedVariationVariationComponent = isResourceVariationComponent(selectedVariation);

  const emptyLayout =
    <Box sx={{ height: 350, display: 'flex', textAlign: 'center', alignItems: 'center', p: 2 }}>
      This preview is only for Variation Components. <br />
      <br />
      Please select one to see a preview of the Variation Components combined together.
    </Box>

  let variation;
  if (isLayerVariationComponent) {
    variation = selectedLayer?.variations[0].parentVariation;
  } else if (isSelectedVariationVariationComponent) {
    variation = selectedVariation;
  } else {
    return emptyLayout;
  }

  const parentVariation = variation;

  const variations = [
    parentVariation,
    ...parentVariation.components
  ]
  const combination = sortVariationsByLayerId(variations)

  const varientComponentPreviewLayout = <Box>
    <NftCombination
      gifPreviewControl={true}
      minWidth={350}
      maxWidth={350}
      combination={combination} />

    <List
      sx={{ width: '100%' }}
      subheader={<ListSubheader>Core Variation</ListSubheader>}
    >
      <VariationListItem
        url={parentVariation.file?.url}
        content={parentVariation.layer.name} />
    </List>
    <List
      sx={{ width: '100%' }}
      subheader={<ListSubheader>Variation Components</ListSubheader>}
    >
      {parentVariation.components.map(variation =>
        <VariationListItem
          key={variation.id}
          url={variation.file?.url}
          content={variation.layer.name} />
      )}
    </List>
  </Box>


  return varientComponentPreviewLayout;
}

const VariationImage = ({ url }) => <img
  style={{
    border: '1px solid #3d3d3d',
    borderRadius: 2,
    boxSizing: 'content-box',
    maxHeight: 60,
    imageRendering: "-webkit-optimize-contrast",
  }}
  alt={url}
  crossOrigin='anonymous'
  src={url}
/>

const VariationListItem = ({ url, content }) => <ListItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
  <VariationImage url={url} />
  <LabelStack label="Layer" content={content} sx={{ textAlign: 'right' }} />
</ListItem>

const RightSidePanel = () =>
  <ToolbarConsumer>
    {({ rightSidePanel }) =>
      typeof rightSidePanel === 'number' && rightSidePanel >= 0 && <RightColumn.SidePanel>
        {((panel) => {
          switch (panel) {
            case 0:
              return <EditorDetails />
            case 1:
              return <VariationComponentDetails />
            default:
              break;
          }
        })(rightSidePanel)}
      </RightColumn.SidePanel>
    }
  </ToolbarConsumer>

export default RightSidePanel;