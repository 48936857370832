import React, { useEffect, useState } from 'react';

import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { TextField } from "@mui/material";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, isAllowed, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      {...(isAllowed ? { isAllowed } : {})}
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const FocusBlurTextField = ({ numbersOnly, numberIsAllowed, initialText, onBlur, sx, noFocusBorder, ...props }) => {
  const [text, setText] = useState(initialText || "");

  const handleChange = event => {
    setText(event.target.value);
  };

  useEffect(() => {
    if (initialText) {
      setText(initialText)
    }
  }, [initialText])

  const noFocusBorderStyles = {
    '.MuiInputBase-input': {
      pt: 0.5,
      pb: 0.5,
      pl: 0,
      pr: 0,
    },
    ".Mui-focused": {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      }
    },
  }

  return <TextField
    value={text}
    size="small"
    onFocus={event => event.target.select()}
    onChange={handleChange}
    {...(numbersOnly ? {
      name: "numberformat",
      InputProps: {
        inputComponent: NumberFormatCustom,
        inputProps: {
          isAllowed: numberIsAllowed
        }
      }
    } : {})}
    onKeyPress={(event) => {
      if (event.key === 'Enter') {
        event.target.blur()
        event.preventDefault();
      }
    }}
    onBlur={(event) => {
      if (text?.length === 0) {
        // No change
        setText(initialText)
      } else if (typeof onBlur === 'function') {
        onBlur(event, text)
      }
    }}
    sx={{
      ...(!props.label ? {
        '.MuiOutlinedInput-notchedOutline': {
          border: 0,
        }
      } : {}),
      ...(noFocusBorder ? noFocusBorderStyles : {}),
      ...sx
    }}
    {...props}
  />
}

FocusBlurTextField.defaultProps = {
  initialText: "",
  numbersOnly: false
}

export default FocusBlurTextField;