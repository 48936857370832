import React, { useState } from 'react';

import FocusBlurTextField from '@components/FocusBlurTextField';
import { UPDATE_COLLECTION_STATE } from '@mutations/collections';
import { useMutation } from '@apollo/client';

const EditableResourceState = (props) => {
  const { numbersOnly, initialText, resource, onNextState, onEditModeChange, sx, ...rest } = props;
  const [editMode, setEditMode] = useState(false);
  const [updateCollectionState] = useMutation(UPDATE_COLLECTION_STATE);

  const updateResourceState = (resource, newValue) => {
    const { __typename, ...state } = onNextState(resource, newValue);
    switch (resource.__typename) {
      case 'Collection':
        updateCollectionState({
          variables: {
            collectionId: resource.id,
            state
          },
        })
        break;
      default:
        throw new Error(`Updating state not supported for type ${resource.__typename}`)
    }
  }

  const onClick = (event) => {
    switch (event.detail) {
      case 2:
        setEditMode(true)
        onEditModeChange(true)
        event.stopPropagation();
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  return (
    <FocusBlurTextField
      noFocusBorder={true}
      numbersOnly={numbersOnly}
      sx={{
        width: '100%',
        '.MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: '#ffffff',
          cursor: 'pointer',
        },
        ...sx
      }}
      inputRef={input => input && input.focus()}
      disabled={!editMode}
      onClick={onClick}
      initialText={initialText}
      onBlur={(event, newValue) => {
        setEditMode(false)
        onEditModeChange(false)
        updateResourceState(resource, newValue)
      }}
      {...rest}
    />
  )
}

EditableResourceState.defaultProps = {
  resource: undefined,
  onEditModeChange: () => { },
  initialText: "",
  onNextState: (resource) => resource.state,
  numbersOnly: false,
};

export default EditableResourceState;