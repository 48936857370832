import { CSS } from '@dnd-kit/utilities';
import Layer from '@components/Layer';
import React from 'react';
import { useSortable } from '@dnd-kit/sortable';

const SortableLayer = (props) => {
  const {
    id,
    depth,
    indentationPadding,
    selected,
    layer,
    onClick,
  } = props;

  // Sortable
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const sortableStyles = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Layer
      sortableRef={setNodeRef}
      sortableStyles={sortableStyles}
      sortableAttributes={attributes}
      sortableListeners={listeners}
      isDragging={isDragging}
      selected={selected}
      layer={layer}
      depth={depth}
      indentationPadding={indentationPadding}
      onSelect={onClick}
    />
  );
};

export default SortableLayer;
SortableLayer.defaultProps = {};
