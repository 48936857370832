import React, { useContext } from 'react';

import { Box } from '@mui/material';
import SquareIconButton from '@components/SquareIconButton';
import Toolbar from '@components/Toolbar';
import ToolbarContext from '@contexts/ToolbarContext';
import TuneIcon from '@mui/icons-material/Tune';

const CombinationDetails = () => {
  const { rightSidePanel, setRightSidePanel } = useContext(ToolbarContext);

  const onClick = (event) => {
    if (rightSidePanel >= 0) {
      setRightSidePanel(undefined)
    } else {
      setRightSidePanel(0)
    }
  };

  return (
    <SquareIconButton
      sx={{
        ...Toolbar.Styles.TabControlIconStyles,
        '&:last-child': {
          mr: 0
        }
      }}
      selected={rightSidePanel === 0}
      onClick={onClick}
      tooltipTitle="Show the details of the selected combination"
    >
      <TuneIcon fontSize="small" />
    </SquareIconButton >
  );
};

const CollectionEditorToolbar = (props) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Toolbar sx={{ flexGrow: 1 }}>
        <Toolbar.ControlsLeft noDivider={true}>
          {/* Local context state */}
          <Toolbar.Tools.ZoomInTool />
          <Toolbar.Tools.ZoomOutTool />
          <Toolbar.Tools.ResetZoomTool />
          <Toolbar.Tools.ShowTransparencyTool />
        </Toolbar.ControlsLeft>
        <Toolbar.ControlsRight noDivider={true}>
          <CombinationDetails />
        </Toolbar.ControlsRight>
      </Toolbar>
    </Box>
  );
};

export default CollectionEditorToolbar;
