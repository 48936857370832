import {
  Box,
  Button,
  Paper,
  Typography,
} from '@mui/material';
import { CREATE_LAYER, UPDATE_LAYER_POSITION } from '@mutations/layers';
import { Content, LeftColumn, RightColumn, ScrollableBox } from '@components/Layout';
import React, { useContext, useEffect } from 'react';
import VariationsUploader, { VariationComponentUploader } from '@components/VariationsUploader';

import AddIcon from '@mui/icons-material/Add';
import { CREATE_SUBCOLLECTION } from '@mutations/subcollections';
import CreativeSpaceContext from '@contexts/CreativeSpaceContext';
import Layers from '@components/Layers';
import RightSidePanel from './RightSidePanel';
import SquareIconButton from '@components/SquareIconButton';
import Subcollections from '@components/Subcollections';
import { ToolbarConsumer } from '@contexts/ToolbarContext';
import ToolbarContext from '@contexts/ToolbarContext';
import Variation from '@components/Variation';
import Variations from '@components/Variations';
import VariationsEditorToolbar from '@components/VariationsEditorToolbar';
import { buildAppendFields } from '@schema/helpers';
import { isResourceVariationComponent } from '@lib/helper';
import { useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

const AddPlusButton = ({ onClick }) => {
  return (
    <SquareIconButton
      onClick={(event) => {
        // This stops the accordion from expanding/closing
        event.stopPropagation();
        onClick(event);
      }}
      sx={{ p: 0.7 }}
    >
      <AddIcon sx={{ fontSize: '1.4rem' }} />
    </SquareIconButton>
  );
};

const VariationComponent = ({ variation }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', p: 1 }}>
      <Box>
        <Variation
          key={variation?.id}
          variation={variation}
          selected={false}
          onSelect={() => { }}
        />
      </Box>
    </Box>
  )
}

const VariationsEditor = () => {
  const {
    collection,
    getSelectedSubcollection,
    setSelectedSubcollection,
    getSelectedLayer,
    setSelectedLayer,
    getSelectedVariation,
    setSelectedVariation,
  } = useContext(CreativeSpaceContext);
  const { setShowUploadPanel } = useContext(ToolbarContext);
  const selectedSubcollection = getSelectedSubcollection();
  const selectedLayer = getSelectedLayer();
  const selectedVariation = getSelectedVariation();

  const isLayerVariationComponent = isResourceVariationComponent(selectedLayer);

  useEffect(() => {
    const haveVariations = selectedLayer?.variations?.length && selectedLayer?.variations[0].file;
    if (!haveVariations) {
      setShowUploadPanel(true)
    }
  }, [selectedLayer])

  // Create Subcollection
  const [createSubcollection] = useMutation(CREATE_SUBCOLLECTION);
  const createSubcollectionParams = {
    variables: { collectionId: collection.id },
    optimisticResponse: {
      createSubcollection: {
        id: uuidv4(),
        __typename: 'Subcollection',
        name: 'Untitled Subcollection',
        collectionId: collection.id,
        layers: [],
        state: {
          required: true,
          weight: 10
        }
      },
    },
    update: (cache, { data: { createSubcollection } }) => {
      cache.modify({
        id: cache.identify({ id: collection.id, __typename: 'Collection' }),
        fields: buildAppendFields(
          'subcollections',
          `Subcollection:${createSubcollection.id}`
        ),
      });
      setSelectedSubcollection(createSubcollection);
    },
    onCompleted: ({ createSubcollection }) => {
      setSelectedSubcollection(createSubcollection);
    },
  };

  // Create Layer
  const [createLayer] = useMutation(CREATE_LAYER);
  const createLayerParams = {
    variables: {
      subcollectionId: selectedSubcollection?.id,
    },
    optimisticResponse: {
      createLayer: {
        id: uuidv4(),
        __typename: 'Layer',
        name: 'Untitled Layer',
        type: 'normal',
        variations: [],
        subcollectionId: selectedSubcollection?.id,
        type: "normal",
        state: {
          clippingMask: false,
          depth: 0,
          parentId: null,
          visibility: true,
          position: 50,
          required: true,
          weight: 10
        },
      },
    },
    update: (cache, { data: { createLayer } }) => {
      cache.modify({
        id: cache.identify({
          id: selectedSubcollection?.id,
          __typename: 'Subcollection',
        }),
        fields: buildAppendFields('layers', `Layer:${createLayer.id}`),
      });
      setSelectedLayer(createLayer);
    },
    onCompleted: ({ createLayer }) => {
      setSelectedLayer(createLayer);
    },
  };

  // Update Layer Position
  const [updateLayerPosition] = useMutation(UPDATE_LAYER_POSITION);

  return (
    <Content>
      <LeftColumn>
        <LeftColumn.ColumnBoxHeader>
          Subcollections
          <AddPlusButton onClick={() => createSubcollection(createSubcollectionParams)} />
        </LeftColumn.ColumnBoxHeader>
        <LeftColumn.ColumnBox>
          <Box sx={{ pl: 1, pr: 1, pb: 4 }}>
            <Subcollections
              subcollections={collection.subcollections}
              selectedSubcollectionId={selectedSubcollection?.id}
              onSelect={(subcollection) => setSelectedSubcollection(subcollection)}
            />
          </Box>
        </LeftColumn.ColumnBox>
      </LeftColumn>
      <LeftColumn>
        <LeftColumn.ColumnBoxHeader>
          Layers
          <AddPlusButton onClick={() => createLayer(createLayerParams)} />
        </LeftColumn.ColumnBoxHeader>
        <LeftColumn.ColumnBox>
          <Box sx={{ pl: 1, pr: 1, pb: 4 }}>
            <Layers
              layers={selectedSubcollection?.layers || []}
              selectedLayerId={selectedLayer?.id}
              onPositionChange={(updatedLayers, layer, newPosition) => {
                updateLayerPosition({
                  variables: {
                    layerId: layer.id,
                    newPosition,
                  },
                  update: (cache, { data: { updateLayerPosition } }) => {
                    cache.modify({
                      id: cache.identify({
                        id: selectedSubcollection?.id,
                        __typename: 'Subcollection',
                      }),
                      fields: {
                        layers: (resources) => updatedLayers.map(layer => ({ __ref: `Layer:${layer.id}` }))
                      },
                    });
                  },

                })
              }}
              onSelect={setSelectedLayer}
            />
          </Box>
        </LeftColumn.ColumnBox>
      </LeftColumn>
      <RightColumn>
        <VariationsEditorToolbar />
        <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
          <Paper elevation={4} sx={{ flexGrow: 1 }}>
            <ToolbarConsumer>
              {({ showUploadPanel, rightSidePanel, setRightSidePanel }) =>
              (selectedLayer ?
                showUploadPanel ?
                  (isLayerVariationComponent ?
                    <VariationComponentUploader
                      collectionId={collection?.id}
                      layerId={selectedLayer?.id}
                      variations={selectedLayer?.variations} />
                    :
                    <VariationsUploader
                      collectionId={collection?.id}
                      layerId={selectedLayer?.id}
                      variations={selectedLayer?.variations} />)
                  :
                  (isLayerVariationComponent ?
                    <VariationComponent variation={selectedLayer?.variations[0]} />
                    :
                    <ScrollableBox>
                      <Variations
                        variations={selectedLayer?.variations || []}
                        selectedVariationId={selectedVariation?.id}
                        onSelect={(variation) => {
                          setSelectedVariation(variation)
                          setRightSidePanel(rightSidePanel)
                        }}
                      />
                    </ScrollableBox>)
                :
                <Box sx={{ width: '100%' }}>
                  <Typography sx={{ textAlign: 'center', p: 2 }} variant="h6">Please select or create a layer</Typography>
                </Box>
              )
              }
            </ToolbarConsumer>

          </Paper>
          <RightSidePanel />
        </Box>
      </RightColumn>
    </Content>
  );
};

export default VariationsEditor;
