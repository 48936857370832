import {
  Avatar,
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper
} from '@mui/material'

import CollectionsIcon from '@mui/icons-material/Collections';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LayersIcon from '@mui/icons-material/Layers';
import { PersistSelectWeightState } from '@components/SelectWeightState';
import { PersistToggleRequiredState } from '@components/ToggleRequiredState';
import React from 'react';
import { ScrollableBox } from '@components/Layout';
import TextWithCount from '@components/TextWithCount';

const ExpandableListItemButton = ({ secondaryAction, itemText, avatar, nestedList, noClick }) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  let ListItemComponent = ListItemButton
  if (noClick) {
    ListItemComponent = ListItem
  }
  return (
    <React.Fragment>
      <ListItemComponent onClick={handleClick}>
        {nestedList && <Box sx={{ mr: 2.5 }}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </Box>}
        <ListItemIcon>
          {avatar}
        </ListItemIcon>
        <ListItemText primary={itemText} />
        {secondaryAction}
      </ListItemComponent>
      {nestedList && <Collapse in={open} timeout="auto" unmountOnExit>
        {nestedList}
      </Collapse>}
    </React.Fragment>
  )
}

const VariationsList = ({ variations }) =>
  <List sx={{ ml: 4.5 }}>
    {variations.map((variation) =>
      <ExpandableListItemButton
        key={variation.id}
        item={variation}
        itemText={variation.file.name}
        avatar={
          <Avatar
            src={variation.file.url}
            imgProps={{
              crossOrigin: "anonymous"
            }}
            variant="square"
            sx={{
              border: '1px solid #3d3d3d',
              borderRadius: 1,
              imageRendering: "-webkit-optimize-contrast"
            }} />
        }
        secondaryAction={
          <PersistSelectWeightState resource={variation} initialWeightState={variation.state.weight} />
        }
        noClick={true}
      />
    )}
  </List>

const LayersList = ({ layers }) =>
  <List sx={{ ml: 5 }}>
    {layers.map((layer) => {
      return (
        <ExpandableListItemButton
          key={layer.id}
          itemText={<TextWithCount text={layer.name} count={layer.variations.length} />}
          avatar={<LayersIcon />}
          secondaryAction={
            <React.Fragment>
              <FormControl size="small">
                <FormControlLabel
                  control={
                    <PersistToggleRequiredState resource={layer} initialRequiredState={layer.state.required} />
                  }
                  label="Required" />
              </FormControl>
              <PersistSelectWeightState resource={layer} initialWeightState={layer.state.weight} disabled={layer.state.required} />
            </React.Fragment>
          }
          nestedList={
            <VariationsList variations={layer.variations} />
          }
        />
      );
    })}
  </List>

const ExpandableHeirarchyView = ({ subcollections }) => {
  return (
    <List>
      {subcollections.map((subcollection) => {
        return (
          <ExpandableListItemButton
            key={subcollection.id}
            itemText={<TextWithCount text={subcollection.name} count={subcollection.layers.length} />}
            avatar={<CollectionsIcon />}
            secondaryAction={
              <PersistSelectWeightState resource={subcollection} initialWeightState={subcollection.state.weight} />
            }
            nestedList={
              <LayersList layers={subcollection.layers} />
            }
          />
        );
      })}
    </List>
  )
}

const RaritySettings = ({ collection }) => {
  const { subcollections } = collection;
  return (
    <Paper elevation={4} sx={{ height: '100%', width: '100%' }}>
      <ScrollableBox>
        <Box sx={{ width: 650, margin: '0 auto' }}>
          <ExpandableHeirarchyView subcollections={subcollections} />
        </Box>
      </ScrollableBox>
    </Paper>
  )
}

export default RaritySettings;