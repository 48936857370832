import React from 'react';
import { UPDATE_LAYER_STATE } from '@mutations/layers';
import { UPDATE_SUBCOLLECTION_STATE } from '@mutations/subcollections';
import { UPDATE_VARIATION_STATE } from '@mutations/variations';
import { useMutation } from '@apollo/client';

const withResourceStateChange = (Component) => (props) => {
  const { resource, onChange, ...rest } = props;
  const [updateSubcollection] = useMutation(UPDATE_SUBCOLLECTION_STATE);
  const [updateLayer] = useMutation(UPDATE_LAYER_STATE);
  const [updateVariation] = useMutation(UPDATE_VARIATION_STATE);

  const updateState = (resource, state) => {
    switch (resource.__typename) {
      case 'Subcollection':
        updateSubcollection({
          variables: {
            subcollectionId: resource.id,
            state
          },
        })
        break;
      case 'Layer':
        updateLayer({
          variables: {
            layerId: resource.id,
            state
          },
        })
        break;
      case 'Variation':
        updateVariation({
          variables: {
            variationId: resource.id,
            state
          },
        })
        break;
      default:
        throw new Error(`Updating state not supported for type ${resource.__typename}`)
    }
  }

  const onHandleChange = (stateKeyName, stateKeyValue) => {
    const { __typename, ...state } = resource.state;
    const newState = {
      ...state,
      [stateKeyName]: stateKeyValue
    }
    updateState(resource, newState);
    onChange && onChange(resource, newState);
  }

  return <Component onChange={onHandleChange} {...rest} />
}

export default withResourceStateChange;